export const LANDING_PAGE_MAIN_TEXT = 'AI generated text is impressive but surprises and mistakes are possible. We help you check the facts, citations and sources of a text block to state the truthfulness of any text block.';

export const LOREM_IPSUM = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse tortor metus, laoreet at orci eget,tincidunt rhoncus nisi. Donec risus felis, porttitor sit amet diam id, elementum faucibus lorem. Aliquam iaculis tellus odio, at commodo nisi commodo ut. Quisque sit.';
export const LOREM_IPSUM_SHORT  ='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ullamcorper leo sit amet ex venenatis, ut vulputate ipsum porta.';
export const HOME_IT_WORKS = 'How it works?';

export const SOURCES_FOUND = 'Sources found';
export const ASSERTION = 'Assertion';

export const MORE_RESOURCES = 'More resources';
export const UNLOCK_FEATURES = 'Unlock all sources and expand features.';

export const PLACEHOLDER_FOR_USERINPUT = "Copy-paste the text from a AI Model (Chat GPT, Microsoft Bing, Google Bard...)";

export const PROCESSING_WAITING_MESSAGE_GUEST = "Create an account to get notified when assertions search is complete.";
  
export const PROCESSING_WAITING_MESSAGE_USER = "You will be notified via email when assertions finding is complete.";
export const SIGN_AS_GUEST = "Sign in as guest";
export const CREATE_ACCOUNT = "Create account";
export const VALIDATE_EMAIL = "Validate email";

export const ALREADY_HAVE_ACCOUNT = "Already have an account?";
export const DONT_HAVE_ACCOUNT = "Don't have an account?";
export const DONT_KNOW_EMAIL = "Don't know your email?";
export const REQUEST_SUPPORT = "Request Support";
export const GET_STARTED_NOW = "Get Started Now";
export const SIGN_IN = "Sign in";
export const SUBMIT = "Submit";
export const PASSWORD_RECOVERY="Password Recovery";
export const PASSWORD_RECOVERY_MESSAGE="Please provide us with the email you used to create account";
export const SIGN_UP = "Sign up";
export const TERMS_OF_USE = "Terms of Use";
export const PRIVACY_POLICY = "Privacy Policy";
export const CONTINUE = "Continue";
export const CONTINUE_AS_GUEST = "Continue as guest";

export const DID_NOT_RECEIVE_EMAIL = "Didn't receive the email?";
export const RESEND = "Resend";
export const BACK_TO_EMAIL = "Back to email";
export const BACK_TO_SIGNIN = "Back to sign in";

export const LOADING_TEXT = "Processing...";
  
  
  