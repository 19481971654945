import React, { useEffect, useRef } from "react";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { PrimaryButton } from "../../components/PrimaryButton";
import { SecondaryButton } from "../../components/SecondaryButton";
import "./style.css";
import { LandingMainContent } from "../../components/LandingMainContent/LandingMainContent";
import { ArticleResultView } from "../../components/ResultView/ResultView";
import { AssertionsContainer } from "../../components/AssertionsContainer/AssertionsContainer";
import { useNavigate } from 'react-router-dom';
import { defaultArticle } from "../../redux/selectors/article.selectors";
import { useDispatch, useSelector } from "react-redux";
import { HOME_IT_WORKS, LANDING_PAGE_MAIN_TEXT, LOREM_IPSUM } from "../../common/constants/app-text";


export const Landing = (): JSX.Element => {
  const animatedDivRefs = useRef<Array<HTMLDivElement | null>>([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleTryNowClick = () => {
    navigate('/process');
  };

  

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && entry.intersectionRatio > 0.5) {
          const animationClasses = ["animate__fadeInDown", "animate__fadeInLeft", "animate__fadeInRight","animate__zoomIn"];
          const animationClass = (entry.target as HTMLElement).dataset.animation || animationClasses[Math.floor(Math.random() * animationClasses.length)];
          entry.target.classList.add("animate__animated", animationClass);
          observer.unobserve(entry.target);
        }
      });
    },{threshold: 0.5});

    animatedDivRefs.current.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      animatedDivRefs.current.forEach((ref) => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
    };
  }, []);

  var assertion = useSelector(defaultArticle)

  return (
    <div className="landing">
      <div className="div">
        <Header
          className="header-instance"
          state="homescreen"
        />
          <LandingMainContent />
          <div className="mb-15">
            <div className="rectangle flex items-center justify-center h-30 w-full bg-gradient-to-b from-white via-white to-transparent">
              <p className="text-center midnight-blue w-3/5  font-normal text-lg leading-normal tracking-normal mt-3 mb-3 animate__animated" data-animation="animate__fadeInDown" ref={(el) => (animatedDivRefs.current[0] = el)}>
                {LANDING_PAGE_MAIN_TEXT}
              </p>
            </div>
            <div className="h-px w-4/5 bg-gray-300 mx-auto relative z-50 "></div>
          </div>
          <div className="rectangle-2" />
          <div className="ellipse-2" />

          <div className="flex flex-col md:flex-row mt-20 gap-7">
            <div className="ellipse-3" />
            <div className="container-how-works-overlay md:w-3/10  p-10 animate__animated" data-animation="animate__fadeInLeft" ref={(el) => (animatedDivRefs.current[1] = el)}>
              <div className="box">
                <div className="ellipse-wrapper">
                  <div className="container-how-works-overlay-bg" />
                  <div className="container-how-works">
                    <div className="text-wrapper-3">{HOME_IT_WORKS}</div>
                    <p className="text-wrapper-21">
                      {LOREM_IPSUM}
                    </p>
                    <div className="flex justify-items-start">
                      <div className="flex space-x-4 mt-10">
                        <PrimaryButton className="" size="medium" stateProp="rest" style="boxed" text="Submit" />
                        <SecondaryButton className="" stateProp="rest" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:w-7/10 w-full relative animate__animated  " >
              <div className="content-preview-container">
              </div>
              <div className="rectangle-5 animate__animated" data-animation="animate__fadeInRight" ref={(el) => (animatedDivRefs.current[2] = el)}>
              <ArticleResultView disableHideInput={true} assertion={assertion}></ArticleResultView>
              </div>
            </div>
          </div>
          <div className="mb-15 mt-15 min-h-200">
            <div className="flex items-center justify-center h-30 w-full min-h-200 relative">
              <p className="text-center text-midnight-blue w-3/5  font-normal text-lg leading-normal tracking-normal mt-3 mb-3 "  data-animation="animate__fadeInDown" ref={(el) => (animatedDivRefs.current[0] = el)}>
              {LOREM_IPSUM}
              </p>
            </div>
          </div>
          <div className="ellipse-5" />
         <>
          <div className="group-wrapper hh-5"  data-animation="animate__zoomIn" ref={(el) => (animatedDivRefs.current[3] = el)} >
          <AssertionsContainer assertion={assertion}  fullWidth={false} enableOverlay={true} ></AssertionsContainer>
            </div>
        
         </>
         <Footer
          className="footer-instance"
          group="https://generation-sessions.s3.amazonaws.com/992bca50d1a1be566686effdf99311ae/img/group-41-1@2x.png"
        />
      </div>
    </div>
  );
};
