import { ProfileError } from '../../common/constants/profile/profile-error';
import { Organization } from '../../common/models/organization/organization';
import { Profile } from '../../common/models/user/profile';
import { User } from '../../common/models/user/user';
import { UserResponse } from '../../common/services/users/models/user-response';
import { UserSignInForm } from '../../components/Modal/SignUpModal/SignUpEmailStep';
import { GuestLoginForm, LoginForm, UpdateProfileForm } from '../../types/models';
import { getCreateActionFromStore, getCreatePayloadActionFromStore } from '../helpers/create-action';

export const PROFILE_STORE_NAME = 'profile';

const createPayloadAction = getCreatePayloadActionFromStore(PROFILE_STORE_NAME);
const createAction = getCreateActionFromStore(PROFILE_STORE_NAME);

const initialize = createAction('initialize');

const login = createPayloadAction<LoginForm>('login');
const loginSuccess = createAction('login success');
const loginFail = createPayloadAction<ProfileError>('login fail');


const guestLogin = createPayloadAction<GuestLoginForm>('guest login');
const guestLoginSuccess = createAction('guest login success');
const guestLoginFail = createPayloadAction<ProfileError>('guest login fail');

const logout = createAction('logout');

const getProfile = createAction('get profile');
const getProfileSuccess = createPayloadAction<Profile>('get profile success');
const getProfileFail = createPayloadAction<ProfileError>('get profile fail');

const updateProfile = createPayloadAction<UpdateProfileForm & { profilePicture?: File }>('update profile');
const updateProfileSuccess = createPayloadAction<Profile>('update profile success');
const updateProfileFail = createPayloadAction<ProfileError>('update profile fail');
const createAccount = createPayloadAction<UserSignInForm>('create account');
const createGuestAccount = createPayloadAction<UserSignInForm>('create guest account');
const createAccountSuccess = createPayloadAction<User>('create account success');
const createAccountFailure = createPayloadAction<ProfileError>('create account fail');

const createGuestAccountSuccess = createPayloadAction<User>('create guest account success');
const createGuestAccountFailure = createPayloadAction<ProfileError>('create guest account fail');

const setActiveOrganization = createPayloadAction<{ id: string }>('set active organization');

const updateActiveOrganization = createPayloadAction<{ name: string; image?: File }>('update active organization');
const updateActiveOrganizationSuccess = createPayloadAction<Organization>('update active organization success');
const updateActiveOrganizationFail = createPayloadAction<ProfileError>('update active organization fail');
const sendEmailVerificationCode = createPayloadAction<{ email: string}>('send email verification code');
const sendEmailVerificationCodeSuccess = createAction('send email verification code success');
const sendEmailVerificationCodeFail = createAction('send email verification code fail');
const showOTPVerificationCode = createPayloadAction<boolean>('show otp verification code');
const showGuestOTPVerificationCode = createPayloadAction<boolean>('show guest otp verification code');
const showPasswordRecoveryScreen = createPayloadAction<boolean>('show password recovery screen');
const enableMockUser = createPayloadAction<boolean>('enable mock user');

const setMockUser = createAction('send email verification code fail');
const setMockUserSuccess = createPayloadAction<Profile>('set mock user success');

export const profileActions = {
  initialize,
  login,
  loginSuccess,
  loginFail,
  guestLogin,
  guestLoginSuccess,
  guestLoginFail,
  logout,
  getProfile,
  getProfileSuccess,
  getProfileFail,
  updateProfile,
  updateProfileSuccess,
  updateProfileFail,
  setActiveOrganization,
  updateActiveOrganization,
  updateActiveOrganizationSuccess,
  updateActiveOrganizationFail,
  sendEmailVerificationCode,
  showOTPVerificationCode,
  showGuestOTPVerificationCode,
  sendEmailVerificationCodeSuccess,
  sendEmailVerificationCodeFail,
  showPasswordRecoveryScreen,
  createAccount,
  createAccountSuccess,
  createAccountFailure,
  createGuestAccount,
  createGuestAccountSuccess,
  createGuestAccountFailure,
  enableMockUser,
  setMockUser,
  setMockUserSuccess
};
