import { createSelector } from "@reduxjs/toolkit";
import { ArticleState, articleSlice  } from "../slices/article.slice";
import { AppState } from "../types/App.type";


const selectUsersState: (state: AppState) => ArticleState = state => state.article;
export const currentArticle = createSelector(selectUsersState, ({ currentArticle }) => currentArticle);

export const processArticleEnabled = createSelector(selectUsersState, ({ processArticleEnabled }) => processArticleEnabled);

export const articleErrorCodes = createSelector(selectUsersState, ({ errorCode }) => errorCode);
export const defaultArticle = createSelector(selectUsersState, ({ defaultArticle }) => defaultArticle);
export const userArticlesList = createSelector(selectUsersState, ({ articlesList }) => articlesList);
export const isProcessingArticle = createSelector(selectUsersState, ({ isProcessingArticle }) => isProcessingArticle);
export const processingInput = createSelector(selectUsersState, ({ userInput }) => userInput);