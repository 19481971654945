import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { CustomError } from './models/error-response';
import { requestInterceptor, responseInterceptor } from './http-interceptors';

type FullFilled<V> = (value: V) => V | Promise<V>;
type Rejected = (error: any) => Promise<CustomError>;
export type RequestInterceptor = { onFulfilled: FullFilled<AxiosRequestConfig>; onRejected: Rejected };
export type ResponseInterceptor = { onFulfilled: FullFilled<AxiosResponse>; onRejected: Rejected };
export const IS_RETRY_HEADER = 'Is-Retry';

class HttpClientImpl {
  private hostname = 'https://api.dev.guardrail.tech';
  private apiVersion = 'v1';
  private http!: AxiosInstance;
  private requestInterceptors: RequestInterceptor[] = [];
  private responseInterceptors: ResponseInterceptor[] = [];

  private mergeConfig(config?: AxiosRequestConfig,portNumber?:number, enableVersion:boolean = true ): AxiosRequestConfig {
    debugger
    const hostname = process.env.REACT_APP_API_URL;
    const isDemoURL = process.env.REACT_APP_ENABLE_MOCK === 'true';
    const enablePortSwitch = process.env.REACT_APP_ENABLE_PORT_SWITCH === 'true';
    let port = 80;
    if(enablePortSwitch){
      port = portNumber ?? 80; 
     this.hostname = `${hostname}:${port}`;
    }
    return {
      headers: {
        ...config?.headers,
      },
      baseURL: `${this.hostname}/${(isDemoURL && !enableVersion) ?'':this.apiVersion}`,
      ...config,
    };
  }

  setUp(port?: number) {
    this.http = axios.create(this.mergeConfig(undefined,port));
     for (const x of this.requestInterceptors) this.http.interceptors.request.use(x.onFulfilled, x.onRejected);
    for (const x of this.responseInterceptors) this.http.interceptors.response.use(x.onFulfilled, x.onRejected);
  }

  addRequestInterceptor = ({ onFulfilled, onRejected }: RequestInterceptor) => {
    this.requestInterceptors.push({ onFulfilled, onRejected });
  };

  addResponseInterceptor = ({ onFulfilled, onRejected }: ResponseInterceptor) => {
    this.responseInterceptors.push({ onFulfilled, onRejected });
  };

  fromConfig<T = any>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.http.request(config);
  }

  post<ResponseType>(url: string, body?: any, config?: { portNumber?: number , enableVersion?:boolean } & AxiosRequestConfig) {
    return this.http.post<ResponseType>(url, body, this.mergeConfig(config,config?.portNumber,config?.enableVersion));
  }

  put<ResponseType>(url: string, body?: any, config?: { portNumber?: number } & AxiosRequestConfig) {
    return this.http.put<ResponseType>(url, body, this.mergeConfig(config,config?.portNumber));
  }

  patch<ResponseType>(url: string, body?: any, config?: { portNumber?: number } & AxiosRequestConfig) {
    return this.http.patch<ResponseType>(url, body, this.mergeConfig(config,config?.portNumber));
  }

  get<ResponseType>(url: string, config?: { portNumber?: number } &  AxiosRequestConfig) {
    return this.http.get<ResponseType>(url, this.mergeConfig(config,config?.portNumber));
  }

  delete<ResponseType>(url: string, config?: { portNumber?: number } & AxiosRequestConfig) {
    return this.http.delete<ResponseType>(url, this.mergeConfig(config,config?.portNumber));
  }
}

const HttpClient = new HttpClientImpl();

HttpClient.addRequestInterceptor(requestInterceptor);

HttpClient.addResponseInterceptor(responseInterceptor);

HttpClient.setUp();

export { HttpClient };
