import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import "./style.css";
import { ArticleResultView } from "../../components/ResultView/ResultView";
import { AssertionsContainer } from "../../components/AssertionsContainer/AssertionsContainer";
import { useSelector } from "react-redux";
import { currentArticle } from "../../redux/selectors/article.selectors";
import { isGuestUser, isLoggedInUser } from "../../redux/selectors/activeUser.selectors";
import MainPage from "../../components/MainPage/MainPage";
import ContentLayout from "../../components/ContentLayout/ContentLayout";
import { FeaturesList } from "../../components/FeaturesList/FeaturesList";
import { UnlockFeaturesBanner } from "../../components/UnlockFeaturesBanner/UnlockFeaturesBanner";



export const ResultView = (): JSX.Element => {
    return (
        <MainPage>
            <Header />
            <ContentLayout>
                <ResultContent />
            </ContentLayout>
            <Footer />
        </MainPage>
    );
};

function ResultContent() {
    const isLoggedIn = useSelector(isLoggedInUser);
    const assertion = useSelector(currentArticle);
    const isGuestAccount = useSelector(isGuestUser);
    return ((
        assertion
            ? <>
                <div>
                    <div className="flex justify-center items-center animate__animated animate__fadeInUp">
                        <div className="w-4/5">
                            <ArticleResultView assertion={assertion} showTags={true} />
                        </div>
                    </div>
                    <div className={`group-wrapper animate__animated animate__fadeInUp animate__delay-1s ${isLoggedIn && !isGuestAccount ? '' : 'hh-5'}`}>
                        <AssertionsContainer assertion={assertion} fullWidth={!isLoggedIn || isGuestAccount} enableOverlay={!isLoggedIn || isGuestAccount}></AssertionsContainer>
                    </div>
                </div>
                {(!isLoggedIn || isGuestAccount) && (<div className="flex justify-center items-center mt-2 mb-2">
                    <div className="w-4/5">
                        <UnlockFeaturesBanner />
                        <FeaturesList isRowLayout = {false} />
                    </div>
                </div>)}
            </>
            : null
    ));
}
