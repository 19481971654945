import { FC, ReactNode } from 'react';

interface PropTypes {
  children: ReactNode;
}

const ContentLayout: FC<PropTypes> = ({ children }: PropTypes) => (
  <main id="main-page" className="mt-15 pb-10 px-10">
    {children}
  </main>   
);

export default ContentLayout;
