import {
  Middleware,
  applyMiddleware,
  combineReducers,
  configureStore,
  createSerializableStateInvariantMiddleware,
} from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { articleReducer } from "./slices/article.slice";
import { appRenderConfigReducer } from "./slices/appSlice";
import rootSaga from "./sagas";
import { isSerializable } from "./helpers/is-serializable";
import { isIterable } from "./helpers/arrays/is-iterable";
import { profileReducer } from "./slices/profile.slice";
import logger from 'redux-logger'
import { layoutReducer } from "./slices/layout.slice";
import { passwordRecoveryReducer } from "./slices/password-recovery.slice";
import { notificationReducer } from "./reducers/notification.reducer";
const configureSerializabilityMiddleware = () => {
  const getEntries = (value: any) =>
    isIterable(value) ? value.entries() : Object.entries(value);
  return createSerializableStateInvariantMiddleware({
    isSerializable,
    getEntries,
    ignoredActions: [],
  });
};

export function configureAppStore() {
  const sagaMiddleware = createSagaMiddleware();

  const middleware: Middleware[] = [sagaMiddleware];

  if (process.env.NODE_ENV === "development") {
    const serializableMiddleware = configureSerializabilityMiddleware();
    middleware.push(serializableMiddleware);
    middleware.push(logger);
  }
 
  const rootReducer = combineReducers({
    article: articleReducer,
    appRenderState: appRenderConfigReducer,
    profile:profileReducer,
    layout: layoutReducer,
    passwordRecover: passwordRecoveryReducer,
    notification: notificationReducer
  });
  const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(middleware),
  });

  sagaMiddleware.run(rootSaga); 

  return { store };
}
