import React from 'react';
import { CrossIcon } from '../../common/icons/CrossIcon';

interface Props {
  onClose: () => void;
}

const CloseButton: React.FC<Props> = ({ onClose }) => {
  return (
    <div className="flex items-center cursor-pointer" onClick={onClose}>
      <label className="text-purple-primary cursor-pointer mr-2">
        Close
      </label>
      <CrossIcon />
    </div>
  );
};

export default CloseButton;