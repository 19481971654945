import { createSlice } from '@reduxjs/toolkit';
import { PROFILE_STORE_NAME, profileActions } from '../actions/profile.actions';
import { ProfileErrorCode } from '../../common/constants/profile/profile-error-code';
import { Profile } from '../../common/models/user/profile';
import { TokenService } from '../../common/services/token/token.service';
import { layoutActions } from '../actions/layout.actions';
import { passwordRecoveryActions } from '../actions/password-recovery.actions';

export interface ProfileState {
  user: Profile;
  errorCode: ProfileErrorCode;
  loading: boolean;
  isOrganizationUpdating: boolean;
  organizationErrorCode: ProfileErrorCode;
  isUserLoggedIn: boolean;
  showSignUpScreen:boolean;
  showLoginScreen:boolean;
  emailVerificationCode:string,
  showVerificationCodeScreen:boolean,
  showPasswordRecoveryScreen:boolean,
  isGuestAccount:boolean,
  showGuestOTPVerificationCode:boolean,
  isLoading:boolean
  enableUserMock:boolean
}

export const initialProfileState: ProfileState = {
  user: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    imageUrl: '',
    activeOrganization: '',
    isPlatformAdmin: false,
    organizations: [],
  },
  errorCode: ProfileErrorCode.None,
  loading: false,
  isOrganizationUpdating: false,
  organizationErrorCode: ProfileErrorCode.None,
  isUserLoggedIn:false,
  showLoginScreen:false,
  showSignUpScreen:false,
  emailVerificationCode: '',
  showVerificationCodeScreen:false,
  showPasswordRecoveryScreen:false,
  isGuestAccount:false,
  showGuestOTPVerificationCode:false,
  isLoading:false,
  enableUserMock:process.env.REACT_APP_ENABLE_MOCK_USER === 'true'
};

export const profileSlice = createSlice({
  name: PROFILE_STORE_NAME,
  initialState: initialProfileState,
  reducers: {
  },
  extraReducers: builder => {
    builder
    .addCase(profileActions.sendEmailVerificationCodeSuccess, state => {
        // state.showVerificationCodeScreen = true;
    })
    .addCase(profileActions.showOTPVerificationCode,  (state, { payload }) => {
      state.showVerificationCodeScreen = payload;
  }).addCase(profileActions.showGuestOTPVerificationCode,  (state, { payload }) => {
    state.showGuestOTPVerificationCode = payload;
}).addCase(profileActions.showPasswordRecoveryScreen,  (state, { payload }) => {
    state.showPasswordRecoveryScreen = payload;
})
      .addCase(profileActions.login, state => {
        state.loading = true;
        state.showLoginScreen = true;
      }).addCase(profileActions.createAccount, state => {
        state.loading = true;
        state.showSignUpScreen = true;
      })
      .addCase(profileActions.createAccountSuccess, state => {
        state.loading = false;
      })
      .addCase(profileActions.setMockUserSuccess, (state, { payload }) => {
        state.user = payload;
        state.isUserLoggedIn = true;
      })
      .addCase(profileActions.createAccountFailure, (state, { payload }) => {
        state.loading = false;
        state.errorCode = payload.errorCode;
      })
      .addCase(profileActions.enableMockUser, (state, { payload }) => {
        state.enableUserMock =payload;
      })
      .addCase(profileActions.createGuestAccount, state => {
        state.loading = true;
      })
      .addCase(profileActions.logout, state => {
        state.isUserLoggedIn = false;
        state.user = initialProfileState.user;
        state.isGuestAccount = false;
      })
      .addCase(layoutActions.setShowSignInModal, state => {
        state.errorCode = ProfileErrorCode.None;
        state.showPasswordRecoveryScreen = false;
      })
      .addCase(layoutActions.setShowGuestSignUpModal, state => {
        state.errorCode = ProfileErrorCode.None;
      })
      .addCase(layoutActions.setShowSignUpModal, state => {
        state.errorCode = ProfileErrorCode.None;
      })
      
      .addCase(profileActions.loginSuccess, state => {
        state.loading = false;
        state.isUserLoggedIn = true;
        console.log("profile state",state);
      }).addCase(profileActions.guestLoginSuccess, state => {
        state.loading = false;
        state.isGuestAccount = true;
        state.isUserLoggedIn = true;
        console.log("profile state",state);
      }).addCase(profileActions.guestLogin, state => {
        state.loading = true;
        console.log("profile state",state);
      })
      .addCase(passwordRecoveryActions.resetPasswordSuccess,state=>{
        state.showPasswordRecoveryScreen = false;
      })
      .addCase(profileActions.loginFail, (state, { payload }) => {
        state.errorCode = payload.errorCode;
        state.loading = false;
      })
      .addCase(profileActions.guestLoginFail, (state, { payload }) => {
        state.errorCode = payload.errorCode;
        state.loading = false;
      })
      .addCase(profileActions.getProfile, state => {
        state.loading = true;
        state.errorCode = ProfileErrorCode.None;
      })
      .addCase(profileActions.getProfileSuccess, (state, { payload }) => {
        state.user = payload;
        state.loading = false;
      })
      .addCase(profileActions.getProfileFail, (state, { payload }) => {
        state.errorCode = payload.errorCode;
        state.loading = false;
      })
      .addCase(profileActions.updateProfile, state => {
        state.loading = true;
        state.errorCode = ProfileErrorCode.None;
      })
      .addCase(profileActions.updateProfileSuccess, (state, { payload }) => {
        state.user = { ...state.user, ...payload };
        state.loading = false;
      })
      .addCase(profileActions.updateProfileFail, (state, { payload }) => {
        state.errorCode = payload.errorCode;
        state.loading = false;
      })
      .addCase(profileActions.setActiveOrganization, (state, { payload }) => {
        state.user.activeOrganization = payload.id;
      })
      .addCase(profileActions.updateActiveOrganization, state => {
        state.isOrganizationUpdating = true;
        state.organizationErrorCode = ProfileErrorCode.None;
      })
      .addCase(profileActions.updateActiveOrganizationFail, (state, { payload }) => {
        state.organizationErrorCode = payload.errorCode;
        state.isOrganizationUpdating = false;
      })
      .addCase(profileActions.createGuestAccountSuccess, (state,{payload} )=> {
        state.loading = false;
        state.showGuestOTPVerificationCode = true;
      })
      .addCase(profileActions.createGuestAccountFailure, (state, { payload }) => {
        state.loading = false;
        state.errorCode = payload.errorCode;
      });

  },
});
export const profileReducer = profileSlice.reducer;
