import { IS_RETRY_HEADER, RequestInterceptor, ResponseInterceptor } from './http-client';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { TokenService } from '../token/token.service';
import StoreProviderService from '../store-provider/store-provider.service';
import { profileActions } from '../../../redux/actions/profile.actions';
import { ErrorResponse } from './models/error-response';

// function setRetryFlag(config: AxiosRequestConfig) {
//   config.headers[IS_RETRY_HEADER] = true;
//   return config;
// }

function hasRetryFlag(config?: AxiosRequestConfig) {
  return config?.headers?.[IS_RETRY_HEADER] || false;
}

const isRequestBypassedByRequestInterceptor = (config: AxiosRequestConfig) => {
  return !!(config.url?.startsWith('/login') || config.url?.startsWith('/account/password_reset'));
};

export const requestInterceptor: RequestInterceptor = {
  onFulfilled: config => {
    if (isRequestBypassedByRequestInterceptor(config)) return config;
    if (config.headers) {
      config.headers['Authorization'] = `Bearer ${TokenService.accessToken}`;
    } else {
      config.headers = { 'Authorization': `Bearer ${TokenService.accessToken}` };
    }
    return config;
  },

  onRejected: error => error,
};

export const responseInterceptor: ResponseInterceptor = {
  onFulfilled: response => response,
  onRejected: (error: AxiosError<ErrorResponse>) => {
    if (error.response?.status === 401 && !hasRetryFlag(error?.config)) {
      StoreProviderService.dispatch(profileActions.logout());
      // TODO: implement logic once frontend wil receive refresh token
      // return HttpClient.fromConfig(setRetryFlag(error.config));
    }

    if (error.response?.status === 403) {
      StoreProviderService.dispatch(profileActions.logout());
    }

    const data: ErrorResponse | undefined = error.response?.data;

    return Promise.reject({ name: data?.error, message: data?.message, statusCode: data?.statusCode });
  },
};
