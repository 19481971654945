import { getCreatePayloadActionFromStore } from "../helpers/create-action";

export const LAYOUT_STORE_NAME = 'layout';

const createPayloadAction = getCreatePayloadActionFromStore(LAYOUT_STORE_NAME);
const setShowSignInModal = createPayloadAction<boolean>('Show Sign In Modal ');
const setShowSignUpModal = createPayloadAction<boolean>('Show Sign Up Modal');
const setShowGuestSignUpModal = createPayloadAction<boolean>('Show Guest Sign Up Modal');
export const layoutActions = {
    setShowSignInModal,
    setShowSignUpModal,
    setShowGuestSignUpModal
}