import { useDispatch } from "react-redux";
import { MORE_RESOURCES, UNLOCK_FEATURES } from "../../common/constants/app-text";
import { PrimaryButton } from "../PrimaryButton";
import { SecondaryButton } from "../SecondaryButton";
import { layoutActions } from "../../redux/actions/layout.actions";

export const UnlockFeaturesBanner = () => {
    const dispatch = useDispatch();
    const onSignInClick = () => {
      dispatch(layoutActions.setShowSignInModal(true));
    }
    return (<div className={`flex flex-col md:flex-row justify-between py-4`}>
                  <div className="flex space-x-6 items-center mb-4 md:mb-0 md:mr-4">
                      <div>
                          <div className='pill-button'>
                              <div className='pill-button-text'>3 {MORE_RESOURCES} </div>
                          </div>
                      </div>
                      <div>
                          <b className='bold-text-message'>{UNLOCK_FEATURES}</b>
                      </div>
                  </div>
                  <div className="flex space-x-4 items-center">
                      <PrimaryButton className="mr-2 md:mr-4" size="medium" stateProp="rest" style="boxed" text="Create Account" />
                      <SecondaryButton onClick={onSignInClick} className="" stateProp="rest" text="Sign In" />
                  </div>
              </div>);
  }
