import { FC } from 'react';
import { isEmpty } from '../../common/helpers/objects/is-empty';
import { Validators } from '../../common/helpers/form-controls/validators';
import { useForm } from '../../common/helpers/form-controls/use-form';
import MessageBox from '../MessageBox';
import Input from '../Input';
import { PrimaryButton } from '../PrimaryButton';
import { MainButton } from '../MainButton';
import { passwordRecoverSelectors } from '../../redux/selectors/password-recover.selectors';
import { useSelector } from 'react-redux';

interface PasswordForm {
  password: string;
  repeatPassword: string;
}

interface PropTypes {
  isResetting?: boolean;
  onSubmit: (password: string) => void;
}

const PASSWORD_REQUIREMENTS = [
  'A minimum of 1 lower case letter (a-z)',
  'A minimum of 1 upper case letter (A-Z)',
  'A minimum of 1 numeric character (0-9)',
  'A minimum of 1 special character (~`!@#$%^&*()-_+={}[]|\\;:"<>,./?)',
];

const Component: FC<PropTypes> = ({ isResetting, onSubmit }) => {

  const isProcessing =  useSelector(passwordRecoverSelectors.isLoading);

  const { handleSubmit, handleChange, data, errors } = useForm<PasswordForm>({
    validations: {
      password: [
        {
          validator: Validators.password,
          message: 'Invalid password',
        },
      ],
      repeatPassword: [
        {
          validator: Validators.equalToField('password'),
          message: "Password doesn't match",
        },
      ],
    },
    onSubmit: () => onSubmit(data.password),
  });

  return (
    <div className='flex flex-col gap-1'>
      <div className="mt-10">
        <h2 className="text-2xl font-bold text-blueGray-900">
          {isResetting ? 'Reset Password' : 'Create Password'}
        </h2>
        {isResetting && <p className="mt-4 text-base text-blueGray-500">Create a new password below</p>}
      </div>
      <div className="mt-6">
        <MessageBox title="Password must have:" type="information">
          <div className="leading-loose">
            <div className="leading-relaxed pt-1">
              {PASSWORD_REQUIREMENTS.map(requirement => (
                <div className="marked-text marked-text-blue">{requirement}</div>
              ))}
            </div>
          </div>
        </MessageBox>
      </div>
      <form onSubmit={handleSubmit} action="#" method="POST">
        <div className="pt-10 my-4">
          <Input
            id="new-password"
            type="password"
            size="sm"
            placeholder="New Password"
            autoComplete="new-password"
            value={data.password}
            error={!!errors.password}
            onChange={handleChange('password')}
          />
         {
         errors.password
         &&  <div className="text-sm font-medium text-red-600 h-5 pt-2 pb-4 box-content">{errors.password}</div>
         }
        </div>
        <div className='my-4'>
          <Input
            id="confirm-password"
            type="password"
            size="sm"
            placeholder="Confirm Password"
            autoComplete="new-password"
            value={data.repeatPassword}
            error={!!errors.repeatPassword}
            onChange={handleChange('repeatPassword')}
          />
          {
          errors.repeatPassword
          && <div className="text-sm font-medium text-red-600 h-5 pt-2 pb-7.5 box-content">{errors.repeatPassword}</div>
          }
        </div>
       <div className='my-4'>
       <MainButton
       isLoading = {isProcessing}
          text={isResetting ? 'Reset Password' : 'Activate Account'}
          onClick={handleSubmit}
          className="px-12"
          stateProp="rest"
          disabled={!isEmpty(errors) || !data.password || !data.repeatPassword}
        />
       </div>
      </form>
    </div>
  );
};

export default Component;
