import PropTypes from "prop-types";
import { Icons } from "../../common/icons/Icons";
import { Logo } from "../Logo";
import { PrimaryButton } from "../PrimaryButton";

import "./style.css";
import { SingInGroup } from "../SignInGroup/SignInGroup";
import { useSelector } from "react-redux";
import { isLoggedInUser } from "../../redux/selectors/activeUser.selectors";
import logo4 from "../../static/img/logo-4.svg"
import { selectShowGuestModal, selectShowSignInModal, selectShowSignUpModal } from "../../redux/selectors/layout.selectors";
import SignUpPage from "../Modal/SignUpModal/SignUpModal";
import SignInPage from "../Modal/SignInModal/SignInModal";
import { GuestSignIn } from "../Modal/GuestSignInModal/GuestSignInModal";
import { userDetails } from "../../redux/selectors/profile.selectors";
import { LoggedInProfileAvatar } from "../Avatar/LoggedInProfileAvatar";
interface Props {
  state?: "homescreen" | "not-logged-in" | "logged-in"; //maintain  based on user state
  className?: any;
}

export const Header = ({ state='not-logged-in', className ='header-instance'}: Props): JSX.Element => {
  const isLoggedIn = useSelector(isLoggedInUser);
  const showLogin = useSelector(selectShowSignInModal);
  const showSignUp = useSelector(selectShowSignUpModal);
  const showGuestSignIn = useSelector(selectShowGuestModal);
 const currentLoggedInUser = useSelector(userDetails)
 
  return (
    <>
    <div className={`header flex items-center justify-between p-4 ${state} ${className}`}>
       <Logo className="logo-instance flex space-x-4" logo={logo4} />
          <div className="flex space-x-4 flex-row-reverse">
         {!isLoggedIn &&  <><SingInGroup/> 
         <PrimaryButton
            className=""
            size="small"
            stateProp="rest"
            style="label"
            text="Privacy Policy"
          />
          <PrimaryButton className="" size="small" stateProp="rest" style="label" text="Terms of Use" /></>}
         
          </div>
      {isLoggedIn && (
        <>
        <div className="flex space-x-4 flex-row-reverse">
          <div>
          <div className="profile-thumbnail">
          <div className="text-sm font-sans text-gray-600 mr-5">
            {`${currentLoggedInUser.firstName} ${currentLoggedInUser.lastName}`}</div>
            <LoggedInProfileAvatar firstName={currentLoggedInUser.firstName} lastName={currentLoggedInUser.lastName} />
           
          </div>
          </div>
        </div>    
        </>
      )}


    </div>
     <SignInPage
        isShown={showLogin}
      />
     <SignUpPage
        isShown={showSignUp}
      />
      <GuestSignIn
        isShown={showGuestSignIn}/>
    </>
  );
};

Header.propTypes = {
  state: PropTypes.oneOf(["homescreen", "not-logged-in", "logged-in"]),
  logoLogo: PropTypes.string,
};
