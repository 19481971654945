import * as React from 'react';
import { memo } from 'react';
import classnames from 'classnames';
import InformationIcon from '../../common/icons/InformationIcon';
import SuccessIcon from '../../common/icons/SuccessIcon';

interface PropTypes {
  title: string;
  type: 'success' | 'information';
  children: React.ReactNode;
  classes?: {
    title: string;
  };
}

const defaultProps: Partial<PropTypes> = {
  classes: undefined,
};

interface StyleObjectTypes {
  backgroundColor: string;
  title: string;
  childrenColor: string;
  icon: React.ReactNode;
}

const Component: React.FC<PropTypes> = memo(({ title, type, children, classes }: PropTypes) => {
  let styleObject: StyleObjectTypes;

  if (type === 'success') {
    styleObject = {
      backgroundColor: 'bg-green-50',
      title: classes?.title || 'text-green-800',
      childrenColor: 'text-green-700',
      icon: <SuccessIcon />,
    };
  } else {
    styleObject = {
      backgroundColor: 'bg-blue-50',
      title: classes?.title || 'text-blue-800',
      childrenColor: 'text-blue-700',
      icon: <InformationIcon />,
    };
  }

  return (
    <div className={classnames('rounded text-sm font-medium flex py-5 pl-5 pr-6', styleObject.backgroundColor)}>
      <div>{styleObject.icon}</div>
      <div className="flex flex-col ml-2 ">
        <div className={styleObject.title}>{title}</div>
        <div className={styleObject.childrenColor}>{children}</div>
      </div>
    </div>
  );
});

Component.defaultProps = defaultProps;

export default Component;
