export enum RoutePath {
    Landing = '/',
    Login = '/login',
    Results = '/results',
    Process = '/process',
    PasswordRecovery = '/password_recovery',
  }



  