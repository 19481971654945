import * as React from 'react';
import classnames from 'classnames';

import { PropTypes, defaultProps } from './IconProps';

const Component: React.FC<PropTypes> = ({ className, ...rest }: PropTypes) => (
  <svg
    className={classnames({ 'w-6 h-6 text-red-600': !className, [className!]: className })}
    fill="currentColor"
    stroke="white"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path strokeLinecap="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
  </svg>
);

Component.defaultProps = defaultProps;

export default Component;
