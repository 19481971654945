import { emailValidator } from './email-validator';
import { maxLengthValidator, minLengthValidator } from './length-validator';
import { passwordValidator } from './password-validator';
import { requiredValidator } from './required-validator';
import { equalToField } from './equal-to-field';

export interface ValidationArguments<ValidatedObject = any> {
  object: ValidatedObject;
}

export const Validators = {
  required: requiredValidator,
  email: emailValidator,
  password: passwordValidator,
  minLength: minLengthValidator,
  maxLength: maxLengthValidator,
  equalToField: equalToField,
};
