import { PayloadAction, createAction } from "@reduxjs/toolkit";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { ArticleService } from "../../common/services/article/article.service";
import { AssertionResult } from "../../types/models";
import { articleActions } from "../actions/article.actions";
import { DefaultAssertionsData } from "../../common/constants/defaultArticle";
import { mapApiResponseToResult, wait } from "../../common/helpers/Helper";
import { ArticleProcessRequest } from "../../common/services/article/models/ArticleProcessRequest";
import { setNotification } from "../actions/notification.actions";

export function* getArticles(action: PayloadAction<string>) {
  try {
    const userArticlesList: AssertionResult[] = yield call(ArticleService.getUserArticlesById, action.payload);
    yield put(articleActions.getUserArticlesListSuccess(userArticlesList));
  } catch (err: any) {
    yield put(articleActions.getUserArticlesListFailure({ message: err.message, statusCode: err.statusCode }));
  }
}

export function* getDefaultArticle() {
  try {
    const defaultArticle: AssertionResult = yield call(ArticleService.getDefaulArticle);
    yield put(articleActions.getDefaultArticleSuccess(defaultArticle));
  } catch (err: any) {
    yield put(articleActions.getDefaultArticleFailure({ message: err.message, statusCode: err.statusCode }));
  }
}

export function* processArticles({ payload }: PayloadAction<string>) {
  try {
    debugger
    const result: ArticleProcessRequest = new ArticleProcessRequest(payload);
     const processedArticle: AssertionResult = yield call(ArticleService.processArticle, result);
    yield put(articleActions.processArticleSuccess(mapApiResponseToResult(processedArticle)));
  } catch (err: any) {
    yield put(articleActions.processArticleFailure({ message: err.message, statusCode: err.statusCode }));
   
  }
  finally {
    yield put(articleActions.processArticleEnabled(false));
  }
}

export const PROCESS_ARTICLE_SAGA = 'Process Article Saga';

export const processArticleSaga = createAction<string>(PROCESS_ARTICLE_SAGA);

export default function* watcher() {
  yield takeLatest(articleActions.processArticle.type, processArticles);
  yield takeEvery(articleActions.getUserArticlesList.type, getArticles);
  yield takeEvery(articleActions.getDefaultArticle.type, getDefaultArticle);
}
