import React from 'react';
import { extractMainDomain, extractBaseUrl, getFormatedDate } from "../../common/helpers/Helper";
import { Assertion, AssertionResult, Result } from "../../types/models";
import { useSelector } from 'react-redux';
import { isLoggedInUser, maxSourceToShow } from '../../redux/selectors/activeUser.selectors';
import { ASSERTION, SOURCES_FOUND } from '../../common/constants/app-text';


interface Props {
  stateProp: "";
  assertion?: Assertion;
  assertionCount: number;
}

interface AssertionResultItemProps {
  result: Result;
}

const AssertionResultItem: React.FC<AssertionResultItemProps> = React.memo(({ result }:AssertionResultItemProps) => {
  return (<div className="assertion-table-row">
    <div className="frame-7">
      <div className="text-wrapper-12"></div>
      <div className="div-wrapper">
        <div className="div-2">
          <span className="text-wrapper-13">
            {extractMainDomain(result.source)}
            <br />
          </span>
          <span className="text-wrapper-14">{extractBaseUrl(result.source)}</span>
        </div>
      </div>
    </div>
    <div className="frame-8">
      <div className="text-wrapper-15"></div>
      <div >
        <p className="text-wrapper-16">
          <a  target='blank' href={result.source}>{result.excerpt}</a>
        </p>
      </div>
    </div>
    <div className="frame-9">
      <div className="text-wrapper-17">{getFormatedDate(new Date())}</div>
    </div>
    <div className="frame-9">
      <p className="text-wrapper-18">
        “{result.excerpt}”
      </p>
    </div>
    <div className="frame-wrapper">
      <div className="frame-10">
        <div className="text-wrapper-19">{result.score.toFixed(2)}</div>
      </div>
    </div>
  </div>);
});


export const AssertionResultsList = ({ stateProp, assertion, assertionCount }: Props): JSX.Element => {
  var currenetAssertion = assertion;
  if (!assertion) {
    //get default assertion
  }
  let sourcesFound = assertion?.topRanks.length;
  const isLoggedIn = useSelector(isLoggedInUser);
  const maxResults= useSelector(maxSourceToShow);
  const renderedResults = isLoggedIn ? assertion?.topRanks : assertion?.topRanks.slice(0, maxResults);

  return (<div className="assertion-container-main">
    <div className="assertion-container">
      <div className="flex items-center space-x-4 mt-3">
        <div className="text-black font-bold text-lg">{ASSERTION} {assertionCount}</div>
        <div className="text-purple-600 text-sm">{sourcesFound} {SOURCES_FOUND}</div>
      </div>
      <div className="flex items-center space-x-4 mt-3">
        <div className="p-wrapper" style={{backgroundColor: assertion?.colorCode}}>
          <p className="p" >{assertion?.articleName}</p>
        </div>
      </div>
    </div>
    <div className="assertions-results-list relative mb-10">
      <div className="assertions-results-headers">
        <div className="overlap-2">
          <div className="group-3">
            <div className="rectangle-7" />
            <div className="assertion-warpper">SOURCE</div>
            <div className="assertion-warpper">ARTICLE</div>
            <div className="assertion-warpper">DATE</div>
            <div className="assertion-warpper">EXCERPT</div>
            <div className="assertion-warpper mr-5">SCORE
            </div>
            <div className="text-wrapper-10 cursor-pointer" title='Linguistic Similarity'></div>
          </div>
        </div>
      </div>
      <div className="assertions-results-content">
        {
          renderedResults?.map((result, index) => (
            <AssertionResultItem result={result} key={index} />
          ))
        }
      </div>
    </div>
  </div>);
}

