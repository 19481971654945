import { PayloadAction } from "@reduxjs/toolkit";
import { GuestLoginForm, LoginForm } from "../../types/models";
import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { profileActions } from "../actions/profile.actions";
import { profile } from "console";
import { ProfileErrorCode } from "../../common/constants/profile/profile-error-code";
import { AuthResponse } from "../../common/services/auth/models/auth-response";
import { AuthService } from "../../common/services/auth/auth.service";
import { TokenService } from "../../common/services/token/token.service";
import { Profile } from "../../common/models/user/profile";
import { ProfileService } from "../../common/services/profile/profile.service";
import { Organization } from "../../common/models/organization/organization";
import { browserHistory } from "../helpers/history/history";
import { RoutePath } from "../../common/constants/route-path";
import { layoutActions } from "../actions/layout.actions";
import { DefaultUserRole, PostUserRequest } from "../../common/services/users/models/post-user-request";
import { UserResponse } from "../../common/services/users/models/user-response";
import { UserService } from "../../common/services/users/user.service";
import { UserSignInForm } from "../../components/Modal/SignUpModal/SignUpEmailStep";
import { passwordRecoveryActions } from "../actions/password-recovery.actions";
import { PasswordRecoverService } from "../../common/password-recover/password-recover.service";
import { setNotification } from "../actions/notification.actions";
import { UserRole } from "../../common/models/user/user-role";
import { User } from "../../common/models/user/user";


function* initialSaga() {
  debugger
  if (!TokenService.accessToken) return;
  yield call(getCurrentUserProfile);
}


function isMockUser():boolean{
  return process.env.REACT_APP_ENABLE_MOCK_USER === 'true'
}


function *setMockProfile(){
  yield put(profileActions.setMockUserSuccess(getDefaultProfile()));
}
const getDefaultProfile = () => {
  return {
    id: '',
    firstName: 'Michael',
    lastName: 'Byrd',
    email: 'michael.byrd@guardrail.tech',
    imageUrl: '',
    activeOrganization: '',
    isPlatformAdmin: false,
    organizations: [],
  }
}
function* login({ payload }: PayloadAction<LoginForm>) {
  debugger
  let errorCode = ProfileErrorCode.None;
  try {
    const result: AuthResponse = yield call(AuthService.authorizeByCredentials, payload.email, payload.password);
    debugger
    if (!result.accessToken) {
      errorCode = ProfileErrorCode.UserNotFoundOrIncorrectPassword;
    }

    TokenService.accessToken = result.accessToken;
    TokenService.userId = result.userId;

    const profile: Profile = yield call(ProfileService.getProfile);
    const isPlatformAdmin: boolean = yield call(ProfileService.getIsPlatformAdmin, TokenService.accessToken);
    profile.isPlatformAdmin = isPlatformAdmin;
    if (profile.organizations.length === 0 || !profile.activeOrganization) {
      errorCode = ProfileErrorCode.UserDoesNotBelongAnyOrganization;
    }

    if (errorCode === ProfileErrorCode.None) {
      yield put(profileActions.getProfileSuccess(profile));
      yield put(profileActions.loginSuccess());
      yield put(setNotification({ title: 'Login Sucessfull', type: 'success' }));
      yield put(layoutActions.setShowSignUpModal(false));
    } else {
      yield put(profileActions.loginFail({ errorCode }));
    }
  } catch (error: any) {
      errorCode = ProfileErrorCode.UserNotFoundOrIncorrectPassword;
    yield put(profileActions.loginFail({ errorCode }));
  }
}
function* logout() {
  TokenService.clear();
  yield put(setNotification({ title: 'Sucessfully logged out', type: 'success' }));
}



function* getCurrentUserProfile() {
  debugger
  let errorCode = ProfileErrorCode.None;
  try {
    const profile: Profile = yield call(ProfileService.getProfile);
    if (errorCode === ProfileErrorCode.None) {
      const isGuestUser = profile.organizations.find((e)=>e.role === UserRole.Guest);
      yield put(profileActions.getProfileSuccess(profile));
      yield put(profileActions.loginSuccess());
      if(isGuestUser){
        yield put(profileActions.guestLoginSuccess());
      }
    } else {
      yield put(profileActions.getProfileFail({ errorCode }));
    }
  } catch (error) {
    errorCode = ProfileErrorCode.UnknownError;
  }
}



function* guestLogin({ payload }: PayloadAction<GuestLoginForm>) {
  let errorCode = ProfileErrorCode.None;
  let defaultPassword = 'Welcome@123';
  try {
    const result: AuthResponse = yield call(AuthService.authorizeByEmail, payload.email ,defaultPassword);
    if (!result.accessToken) {
      errorCode = ProfileErrorCode.UserNotFoundOrIncorrectPassword;
    }

    TokenService.accessToken = result.accessToken;
    TokenService.userId = result.userId;

    const profile: Profile = yield call(ProfileService.getProfile);
    const isPlatformAdmin: boolean = yield call(ProfileService.getIsPlatformAdmin, TokenService.accessToken);
    profile.isPlatformAdmin = isPlatformAdmin;
    if (profile.organizations.length === 0 || !profile.activeOrganization) {
      errorCode = ProfileErrorCode.UserDoesNotBelongAnyOrganization;
    }

    if (errorCode === ProfileErrorCode.None) {
      yield put(profileActions.getProfileSuccess(profile));
      yield put(profileActions.guestLoginSuccess());
      yield put(layoutActions.setShowGuestSignUpModal(false));
      yield put(setNotification({ title: 'Logged in as guest', type: 'success' }));
    } else {
      yield put(profileActions.guestLoginFail({ errorCode }));
    }
  } catch (error: any) {
     errorCode = ProfileErrorCode.UserNotFoundOrIncorrectPassword;
    yield put(profileActions.guestLoginFail({ errorCode }));
  }
}


function* createUserProfile(payload: UserSignInForm, roleName: UserRole) {
  debugger
  let errorCode = ProfileErrorCode.None;
  try {
    const user: PostUserRequest = {
      email: payload.email,
      firstName: roleName === UserRole.Guest ? "Guest" : payload.firstName,
      lastName: roleName === UserRole.Guest ? "Profile" : payload.lastName,
      organizationId: '64efe42cc13b0ba39fc8d35a',
      role: roleName,
      roleId:DefaultUserRole,
    };
    const result: User = yield call(UserService.post, user);
    
    if (errorCode === ProfileErrorCode.None) {
      debugger
      if (roleName === UserRole.User) {
        yield put(passwordRecoveryActions.setPasswordRecoveryRequest(payload.email));
        yield put(profileActions.createAccountSuccess(result));
      yield put(setNotification({ title: 'Account created for '+result.email, type: 'success' }));

      } else {
        yield put(setNotification({ title: 'OTP Sent to  '+result.email, type: 'success' }));
        yield put(profileActions.createGuestAccountSuccess(result));
      }
    } else {
      if (roleName === UserRole.User) {
        yield put(profileActions.createAccountFailure({ errorCode }));
      } else {
        yield put(profileActions.createGuestAccountFailure({ errorCode }));
      }
    }
  } catch (error: any) {
      errorCode = ProfileErrorCode.UserExists;
    if (roleName === UserRole.User) {
      yield put(profileActions.createAccountFailure({ errorCode }));
    } else {
      yield put(profileActions.createGuestAccountFailure({ errorCode }));
    }
  }
}

function* createProfile({ payload }: PayloadAction<UserSignInForm>) {
  yield call(createUserProfile, payload, UserRole.User);
}

function* createGuestProfile({ payload }: PayloadAction<UserSignInForm>) {
  debugger
  yield call(createUserProfile, payload, UserRole.Guest);
}


function* sendEmailVerificationCode({ payload }: PayloadAction<{email:string}>) {
try {
  yield put(passwordRecoveryActions.setPasswordRecoveryRequest(payload.email));
} catch (error) {
  console.log(error);
}
}

export default function* watcher() {
  yield takeLatest(profileActions.initialize.type, initialSaga);
  yield takeLatest(profileActions.setMockUser.type, setMockProfile);
  yield takeLatest(profileActions.login.type, login);
  yield takeLatest(profileActions.logout.type, logout);
  yield takeLatest(profileActions.sendEmailVerificationCode.type, sendEmailVerificationCode);
  yield takeLatest(profileActions.createAccount.type, createProfile);
  yield takeLatest(profileActions.createGuestAccount.type, createGuestProfile);
  yield takeLatest(profileActions.guestLogin.type, guestLogin);
  yield takeLatest(profileActions.getProfile.type, getCurrentUserProfile);
}
