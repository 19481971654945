import { useDispatch } from "react-redux";
import { CenterLabel } from "../../CenterLabel/CenterLabel";
import { Divider } from "../../Divider/Divider";
import { LogoDetailed } from "../../LogoDetailed/LogoDetailed";
import { profileActions } from "../../../redux/actions/profile.actions";
import { FC, useState } from "react";
import OTPStepContent from "../../OTPStepContent/OTPStepContent";
import { BACK_TO_EMAIL } from "../../../common/constants/app-text";
import { passwordRecoveryActions } from "../../../redux/actions/password-recovery.actions";
interface PropTypes {
  otpAction?: string;
  onClose?: () => void;
  isGuestAccount?: boolean;
  onBackNavigation?: () => void;
}
export const  OTPVerificationStep:FC<PropTypes>  =({onBackNavigation, isGuestAccount = false,  otpAction = 'Continue as guest'}) => {
var dispatch = useDispatch();

const [animationClass, setAnimationClass] = useState("");
  const goBack = () => {
    setAnimationClass("animate__animated animate__flipOutX");
    setTimeout(() => {
     if(onBackNavigation){
      onBackNavigation()
     }
    }, 500); 
  }
  
    return (
          <div className={`flex flex-col items-center justify-center mt-5 ${animationClass}`}>
          <div className="block group mx-5 my-5">
            <LogoDetailed />
          </div>
          <OTPStepContent  isGuestAccount = {isGuestAccount} otpAction={otpAction}></OTPStepContent>
          <Divider />
         <CenterLabel  onClick={goBack} text={BACK_TO_EMAIL} ></CenterLabel>
        </div>);
  }