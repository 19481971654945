import { FC, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SIGN_IN, DONT_HAVE_ACCOUNT, GET_STARTED_NOW } from "../../../common/constants/app-text";
import { useForm } from "../../../common/helpers/form-controls/use-form";
import { Validators } from "../../../common/helpers/form-controls/validators";
import { layoutActions } from "../../../redux/actions/layout.actions";
import { isLoading, selectProfileErrorMessage, showOTPScreen } from "../../../redux/selectors/profile.selectors";
import CloseButton from "../../CloseButton/CloseButton";
import { Divider } from "../../Divider/Divider";
import Input from "../../Input";
import { LogoDetailed } from "../../LogoDetailed/LogoDetailed";
import { MainButton } from "../../MainButton";
import { MessageWithAction } from "../../MessageWithAction/MessageWithAction";
import { SignInProvidersGroup } from "../../SignInProvidersGroup/SignInProvidersGroup";
import { TermsAndConditions } from "../../TermsAndConditions/TermsAndConditions";
import { profileActions } from "../../../redux/actions/profile.actions";
import { log } from "console";
import { isEmpty } from "../../../common/helpers/objects/is-empty";
import { LoginForm } from "../../../types/models";
import ErrorBox from "../../ErrorBox";

export const SignInModalContent: FC = () => {
    const rememberMeInputRef = useRef<HTMLInputElement>(null);
    const errorMessage = useSelector(selectProfileErrorMessage);
    const dispatch = useDispatch();
    const isProcessing = useSelector(isLoading);
    const enableOTPScreen = useSelector(showOTPScreen);
    const { handleChange, handleSubmit, data, initialize ,errors} = useForm<LoginForm>({
      validations: {
        email: [
          {
            validator: Validators.email,
            message: 'Invalid email format',
          },
          {
            validator: Validators.required,
            message: 'Email is required',
          },
        ],
        password: [
          {
            validator: Validators.password,
            message: 'Invalid password',
          },
          {
            validator: Validators.required,
            message: 'Password is required',
          },
        ],
      },
      onSubmit: () => {
      console.log('sign in data',data);
       validateSignIn(data);
      },
    });
  
    const onSignUpClose = () => {
      dispatch(layoutActions.setShowSignInModal(false));
    };
  
    const validateSignIn = (data:LoginForm) => {
      dispatch(profileActions.login(data));
    };
  
    const showSignUpModal = () => {
      dispatch(layoutActions.setShowSignUpModal(true));
    };
  
    const showPasswordRecovery = () => {
        dispatch(profileActions.showPasswordRecoveryScreen(true));
      };
    return (
        <div className="flex flex-col items-center justify-center gap-1">
          <div className="flex justify-end w-full items-center">
            <CloseButton onClose={onSignUpClose} />
          </div>
          <div className="block group mx-5">
            <LogoDetailed />
          </div>
          <h2 className="mt-4  font-sans text-center text-midnight-blue font-bold">{SIGN_IN}</h2>
         <div className="w-full">
         <Input
            id="signin-user-email"
            className="w-full pb-2  py-2 mt-4 "
            type="text"
            size="sm"
            placeholder="Email address"
            value={data.email}
            error={!!errors.email}
            onChange={handleChange('email')}
          />
          {errors.email && <div className="text-sm font-medium text-red-600 h-5 pt-2 pb-7.5 box-content">{errors.email}</div>}

         </div>
  
         <div className="w-full">
         <Input
            id="user-password"
            className="w-full pb-2  py-2 "
            type="password"
            size="sm"
            autoComplete="off"
            placeholder="Password"
            value={data.password}
            error={!!errors.password}
            onChange={handleChange('password')}
          />
            {errors.password && <div className="text-sm font-medium text-red-600 h-5 pt-2 pb-7.5 box-content">{errors.password}</div>}
         </div>
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center">
              <input
                id="remember_me"
                name="remember_me"
                type="checkbox"
                className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
                ref={rememberMeInputRef}
              />
              <label htmlFor="remember_me" className="ml-2 block text-sm text-gray-400 font-sansInte">
                Remember me
              </label>
            </div>
            <div className="text-sm">
              <p onClick={showPasswordRecovery} className="ml-1 cursor-pointer  text-primary-blue font-bold  text-lg">Forgot password?</p>
            </div>
          </div>
     {errorMessage && (<div className="w-full my-2">  <ErrorBox errorMessage={errorMessage} /></div>)}      
          <MainButton
            isLoading = {isProcessing}
            onClick={handleSubmit}
            className="w-full w-100 px-4  mt-2"
            stateProp="rest"
            text={SIGN_IN}
            disabled={!isEmpty(errors) || !data.email  || !data.password}
          />
          <MessageWithAction message={DONT_HAVE_ACCOUNT} onAction={showSignUpModal} action={GET_STARTED_NOW} />
          <Divider />
          <SignInProvidersGroup />
          <TermsAndConditions />
        </div>
    );
  };