import PropTypes from "prop-types";
import React from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";

interface Props {
  className: any;
  logo: string;
}

export const Logo = ({ className, logo = "../../static/img/logo-2.svg" }: Props): JSX.Element => {
  
  const navigate = useNavigate();
  const handleTryNowClick = () => {
    navigate('/');
  };

  return <img  
  onClick = {handleTryNowClick}
  className={`logo  cursor-pointer ${className}`} alt="Logo" src={logo} />;
};

Logo.propTypes = {
  logo: PropTypes.string,
};
