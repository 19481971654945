import { FC, useRef } from 'react';
import Modal from '../Modal';
import { Validators } from '../../../common/helpers/form-controls/validators';
import { useForm } from '../../../common/helpers/form-controls/use-form';
import { useDispatch, useSelector } from 'react-redux';
import { layoutActions } from '../../../redux/actions/layout.actions';
import { showOTPScreen, showPasswordRecovery } from '../../../redux/selectors/profile.selectors';
import { CREATE_ACCOUNT, ALREADY_HAVE_ACCOUNT, SIGN_IN, GET_STARTED_NOW, DONT_HAVE_ACCOUNT } from '../../../common/constants/app-text';
import CloseButton from '../../CloseButton/CloseButton';
import { Divider } from '../../Divider/Divider';
import Input from '../../Input';
import { LogoDetailed } from '../../LogoDetailed/LogoDetailed';
import { MainButton } from '../../MainButton';
import { MessageWithAction } from '../../MessageWithAction/MessageWithAction';
import { SignInProvidersGroup } from '../../SignInProvidersGroup/SignInProvidersGroup';
import { TermsAndConditions } from '../../TermsAndConditions/TermsAndConditions';
import { SignInModalContent } from './SignInModalContent';
import PasswordRecoveryModal from '../PasswordRecoveryModal/PasswordRecoveryModal';
interface PropTypes {
  orgId?: string;
  isShown: boolean;
  onClose?: () => void;
}

interface FormModel {
  firstName: string;
  lastName: string;
  email: string;
}
const SignInPage: FC<PropTypes> = ({ isShown, onClose }) => {
    const showPasswordRecoveryScreen = useSelector(showPasswordRecovery);
    const rememberMeInputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const  enableOTPScreen = useSelector(showOTPScreen);
  const { handleChange, data, initialize } = useForm({
    validations: {
    
     
      email: [
        {
          validator: Validators.email,
          message: 'Invalid email format',
        },
        {
          validator: Validators.required,
          message: 'Email is required',
        },
      ],
    },
    onSubmit: () => {
      if (onClose) {
        onClose();
      }
    },

  });

  const initializeForm = () => {
    initialize({ firstName: '', lastName: '', email: '' } as FormModel);
  };

  const onSignUpClose = () => {
    dispatch(layoutActions.setShowSignInModal(false));
  }

  const validateSignIn = () => {
    // dispatch(layoutActions.setShowSignInModal(false));
  }

  const showSignUpModal = () => {
    dispatch(layoutActions.setShowSignUpModal(true));
  }
  
  return (
    <div>
      <Modal
        isShown={isShown}
        animation="fade"
        centered
        withBackdrop
        onBackdropClick={onClose}
        onAfterClose={initializeForm}
      >
        <div className="border rounded-md border-gray-100 border-solid bg-white shadow-md p-5 w-500 " id="SignInModal">
           {showPasswordRecoveryScreen && (<PasswordRecoveryModal/>) }
           {!showPasswordRecoveryScreen && (<SignInModalContent/>)} 
        </div>
      </Modal>
    </div>
  );
};

export default SignInPage;

