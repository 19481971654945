import * as React from 'react';
import { memo } from 'react';
import classnames from 'classnames';

import InputPropTypes from './Input.prop-types';
import ExclamationCircleFilledIcon from '../../common/icons/ExclamationCircleFilledIcon';

const defaultProps: Partial<InputPropTypes> = {
  ariaLabel: 'custom-input',
  className: '',
  classes: {},
  defaultValue: undefined,
  error: false,
  inputLabel: '',
  name: '',
  placeholder: '',
  value: undefined,
  onChange: undefined,
  maxLength: undefined,
  accept: '',
  size: 'md',
  autoComplete: 'on',
  readOnly: false,
  disabled: false,
};

const Component: React.FC<InputPropTypes> = memo(
  ({
    ariaLabel,
    className,
    classes,
    error,
    id,
    inputLabel,
    name,
    placeholder,
    defaultValue,
    value,
    type,
    accept,
    maxLength,
    size,
    onChange,
    autoComplete,
    readOnly,
    disabled,
    min,
    max,
    step,
  }: InputPropTypes) => {
    return (
      <label className={classnames('text-left', className, classes?.label ? classes?.label : 'block')}>
        {inputLabel && (
          <span
            className={classnames('block text-sm pb-2 text-blueGray-800 font-medium', classes ? classes.labelSpan : '')}
          >
            {inputLabel}
          </span>
        )}
        <div className="relative">
          <input
  id={id}
  type={type}
  name={name}
  className={classnames(
    'text-sm font-normal',
    `font-inter text-base font-normal border focus:outline-none rounded focus:border-blue-400 ${
      size === 'md' ? 'px-3' : 'py-3'
    } px-3 block w-full`,
    error ? 'border-red-500' : '',
    classes ? classes.input : '',
    disabled ? 'text-blueGray-400 bg-gray-100' : ' text-blueGray-800',
    'text-midnight-blue'
  )}
  placeholder={placeholder}
  value={value}
  defaultValue={defaultValue}
  onChange={onChange}
  aria-label={ariaLabel}
  maxLength={maxLength}
  accept={accept}
  autoComplete={autoComplete}
  readOnly={readOnly}
  aria-autocomplete='none'
  disabled={disabled}
  min={type === 'number' ? min : undefined}
  max={type === 'number' ? max : undefined}
  step={type === 'number' ? step || 1 : undefined}
/>
          {maxLength ? (
            <div className="absolute text-sm text-blueGray-400 w-full h-full top-0 right-1.5 flex justify-end items-center">
              {value?.length || defaultValue?.length || 0} / {maxLength}
            </div>
          ) : null}
          {error ? (
            <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
              {<ExclamationCircleFilledIcon /> }
            </div>
          ) : null}
        </div>
      </label>
    );
  },
);

Component.defaultProps = defaultProps;

export default Component;
