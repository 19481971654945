import { EmailValidateResponse } from './models/email-validate-response';
import { EmailValidateRequest } from './models/email-validate-request';
import { PasswordResetRequest } from './models/password-reset-request';
import { HttpClient } from '../services/http';

class PasswordRecoverServiceImpl {
  sendRecoverRequest(email: string) {
    return HttpClient.post<void>('/account/password_reset/request', { email },{portNumber:3002}).then(response => response.data);
  }

  setEmailValidate(request: EmailValidateRequest) {
    return HttpClient.post<EmailValidateResponse>('/account/password_reset/validate', request,{portNumber:3002}).then(
      response => response.data,
    );
  }

  resetPassword(request: PasswordResetRequest) {
    return HttpClient.post('/account/password_reset/confirm', request,{portNumber:3002}).then(response => response.data);
  }
}

const PasswordRecoverService = new PasswordRecoverServiceImpl();

export { PasswordRecoverService };
