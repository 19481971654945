import * as React from 'react';
import classnames from 'classnames';

import { PropTypes, defaultProps } from './IconProps';

const Component: React.FC<PropTypes> = ({ className, ...rest }: PropTypes) => (
  <svg
    className={classnames('w-4 h-4 text-blue-400', className)}
    fill="currentColor"
    stroke="none"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g fillRule="evenodd">
      <path
        d="M16 8c0 4.418-3.582 8-8 8s-8-3.582-8-8 3.582-8 8-8 8 3.582 8 8zM9 4c0 .552-.448 1-1 1s-1-.448-1-1 .448-1 1-1 1 .448 1 1zM7 7c-.552 0-1 .448-1 1s.448 1 1 1v3c0 .552.448 1 1 1h1c.552 0 1-.448 1-1s-.448-1-1-1V8c0-.552-.448-1-1-1H7z"
        transform="translate(-284.000000, -563.000000) translate(266.000000, 543.000000) translate(18.000000, 20.000000)"
      />
    </g>
  </svg>
);

Component.defaultProps = defaultProps;

export default Component;
