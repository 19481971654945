// routes.ts
import { RouterProvider } from 'react-router-dom';
import React, { Suspense, useEffect } from 'react';
import Loading from './components/Loader/Loader';
import { router } from './components/Router/Router';
import { TokenService } from './common/services/token/token.service';
import { useDispatch, useSelector } from 'react-redux';
import { profileActions } from './redux/actions/profile.actions';
import { userDetails } from './redux/selectors/profile.selectors';
import  Notification  from './components/Notification';
function App() {
const dispatch = useDispatch();
const user = useSelector(userDetails);
  console.log(console.log(process.env.REACT_APP_ENV));
useEffect(() => {
  if(TokenService && TokenService.accessToken && TokenService.accessToken.length > 0 && (!user.id) ){
    console.log(user);
    dispatch(profileActions.initialize());
  }
}, [user]);
  return (
    <>
    <Suspense fallback={<Loading />}>
          <RouterProvider router={router} />
        </Suspense>
    <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-40 mt-10"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          <Notification />
        </div>
      </div>
    </>
        
  );
}

export default App;


