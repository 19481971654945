import { NotificationProps } from '../../common/models/notification/notification';
import { CLEAR_NOTIFICATION, NotificationActionType, SET_NOTIFICATION } from '../types/Notification.type';

export const setNotification = (payload: NotificationProps): NotificationActionType => ({
  type: SET_NOTIFICATION,
  payload,
});

export const clearNotification = (): NotificationActionType => ({
  type: CLEAR_NOTIFICATION,
});
