import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { RoutePath } from "../../common/constants/route-path";
import { Landing } from "../../views/Landing";
import { ResultView } from "../../views/Landing/ResultView";
import ErrorPage from "../ErrorPage/ErrorPage";
import { ArticleProcess } from "../../views/Processing/ArticleProcess/ArticleProcess";
import { PasswordRecovery } from "../../views/PasswordRecovery/components/PasswordRecovery";

export const router = createBrowserRouter([
    {
      path: RoutePath.Landing,
      element: <Landing />,
      errorElement: <ErrorPage />
    },
    {
      path: RoutePath.Process,
      element: <ArticleProcess />,
      errorElement: <ErrorPage />
    },
    {
      path: RoutePath.PasswordRecovery,
      element: <PasswordRecovery/>,
      errorElement: <ErrorPage />,
    },
    {
      path: RoutePath.Results,
      element: < ResultView/>,
      errorElement: <ErrorPage />
    },
  ]);
  