import { AssertionResult, HttpErrorResponse } from "../../types/models";
import { getCreateActionFromStore, getCreatePayloadActionFromStore } from "../helpers/create-action";
import { ArticleProcessRequest } from "../../common/services/article/models/ArticleProcessRequest";

export const ARTICLE_STORE_NAME = 'Article Store';


const createPayloadAction = getCreatePayloadActionFromStore(ARTICLE_STORE_NAME);
const createAction = getCreateActionFromStore(ARTICLE_STORE_NAME);

const getDefaultArticle = createAction('Get Default Article');
const getDefaultArticleSuccess = createPayloadAction<AssertionResult>('Get Default Success');
const getDefaultArticleFailure = createPayloadAction<HttpErrorResponse>('Get Default Failure');


const processArticle = createPayloadAction<string>('Process Articles');


const processArticleEnabled = createPayloadAction<boolean>('Process Articles Enabled');
const processArticleSuccess = createPayloadAction<AssertionResult>('Process Success');
const processArticleFailure = createPayloadAction<HttpErrorResponse>('Process Failure');


const getUserArticlesList = createPayloadAction<string>('Get User Articles List');
const getUserArticlesListSuccess = createPayloadAction<AssertionResult[]>('Get User Articles List Success');
const getUserArticlesListFailure = createPayloadAction<HttpErrorResponse>('Get User Articles List Failure');


export const articleActions = {
  processArticle,
  processArticleSuccess,
  processArticleEnabled,
  processArticleFailure,
  getUserArticlesList,
  getUserArticlesListSuccess,
  getUserArticlesListFailure,
  getDefaultArticle,
  getDefaultArticleSuccess,
  getDefaultArticleFailure
}