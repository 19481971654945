import { useDispatch, useSelector } from "react-redux";
import { CREATE_ACCOUNT, ALREADY_HAVE_ACCOUNT, SIGN_IN, CONTINUE, CONTINUE_AS_GUEST, SIGN_AS_GUEST } from "../../../common/constants/app-text";
import CloseButton from "../../CloseButton/CloseButton";
import { Divider } from "../../Divider/Divider";
import Input from "../../Input";
import { LogoDetailed } from "../../LogoDetailed/LogoDetailed";
import { MainButton } from "../../MainButton";
import { MessageWithAction } from "../../MessageWithAction/MessageWithAction";
import { SignInProvidersGroup } from "../../SignInProvidersGroup/SignInProvidersGroup";
import { TermsAndConditions } from "../../TermsAndConditions/TermsAndConditions";
import { profileActions } from "../../../redux/actions/profile.actions";
import { layoutActions } from "../../../redux/actions/layout.actions";
import { Validators } from "../../../common/helpers/form-controls/validators";
import { useForm } from "../../../common/helpers/form-controls/use-form";
import { LoginForm } from "../../../types/models";
import { UserSignInForm } from "../SignUpModal/SignUpEmailStep";
import { isEmpty } from "../../../common/helpers/objects/is-empty";
import ErrorBox from "../../ErrorBox";
import { isLoading, selectProfileErrorMessage } from "../../../redux/selectors/profile.selectors";
import { isMockUser } from "../../../redux/selectors/activeUser.selectors";


export const  GuestSignInEmailSetup = () => {

  var dispatch = useDispatch();
  const mockAsLoggedInUser = useSelector(isMockUser);
  const isProcessing = useSelector(isLoading);
  const errorMessage = useSelector(selectProfileErrorMessage);
  const validateEmailWithOTP = (data:UserSignInForm) => {
    debugger
    dispatch(profileActions.createGuestAccount(data));
  };


  const { handleChange, handleSubmit, data, errors} = useForm<UserSignInForm>({
    validations: {
      email: [
        {
          validator: Validators.email,
          message: 'Invalid email format',
        },
        {
          validator: Validators.required,
          message: 'Email is required',
        },
      ]
    },
    onSubmit: () => {
      validateEmailWithOTP(data)
    },
  });

  const onSignInClose = () => {
    dispatch(layoutActions.setShowGuestSignUpModal(false));
  }
  
  const enableSignInflow = () => {

    if(mockAsLoggedInUser){
      dispatch(profileActions.setMockUser());
      dispatch(layoutActions.setShowGuestSignUpModal(false));
    }else{
      dispatch(layoutActions.setShowGuestSignUpModal(false));
       dispatch(layoutActions.setShowSignInModal(true));
    }
   
  }
    return (
    <div className="flex flex-col items-center justify-center">
          <div className="flex justify-end w-full items-center">
            <CloseButton onClose={onSignInClose} />
          </div>
          <div className="block group mx-5">
            <LogoDetailed />
          </div>
          <h2 className="mt-4 font-sans text-center text-midnight-blue font-bold">{SIGN_AS_GUEST}</h2>
      <p className="w-full  text-gray-400 font-sansInter text-lg">
        Please enter your email address to continue as a guest user.
      </p>
         <div className="w-full">
         <Input id="guest-login-email"
           className="w-full pb-2  py-2" 
           type="text"
            size="sm"
             disabled={false}
              placeholder='Email address'
              value={data.email}
              error={!!errors.email}
              onChange={handleChange('email')} />
               {errors.email && <div className="text-sm font-medium text-red-600 h-5 pt-2 pb-7.5 box-content">{errors.email}</div>}
         </div>
        {errorMessage && (<div className="w-full my-2"> {errorMessage && <ErrorBox errorMessage={errorMessage} />}</div>)} 
          <MainButton
            isLoading = {isProcessing}
          onClick={handleSubmit} disabled={!isEmpty(errors) || !data.email} className="w-full w-100 px-4  mt-2" stateProp="rest" text={CONTINUE} />
          <MessageWithAction message={ALREADY_HAVE_ACCOUNT}  onAction={enableSignInflow} action={SIGN_IN} />
          <div className="mb-4"></div>

        </div>);
  }
