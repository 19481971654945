import React from 'react';
import { AssertionResult } from '../../types/models';

const colors = ['#d6f5f8', '#ffe5e3', '#f6eaff', '#ecf0f7', '#f6f9ec', '#ecf1f8', '#f3f6e6', '#f0ebeb', '#f8e8e8'];

interface HighlightedParagraphState{
  currentAssertion?:AssertionResult;
}

const HighlightedParagraph = ({currentAssertion}:HighlightedParagraphState) => {
  const paragraph = currentAssertion?.article ?? "";
  const highlightedSentences = [...currentAssertion?.assertions ?? []] ?? [];
  
  // Find all the matching sentences from the paragraph and store their starting indexes.
  const sentenceIndexes = highlightedSentences.map((sentence) => {
    const subWords = sentence.articleName.split(' ');

    // Check for complete sentence match
    let sentenceIndex = paragraph.indexOf(sentence.articleName);

    // Ignore first, second, and third words of the sentence
    if (sentenceIndex === -1 && subWords.length > 3) {
      const subSentence = subWords.slice(3).join(' ');
      sentenceIndex = paragraph.indexOf(subSentence);
    }

    return { index: sentenceIndex, sentence: sentence.articleName, colorCode: sentence.colorCode, articleIndex: sentence.articleIndex };
  }).filter((item) => item.index !== -1).sort((a, b) => a.index - b.index);

  let highlightedParagraph = [];
  let start = 0;
  sentenceIndexes.forEach((item, index) => {
    const highlightStyle = {
      backgroundColor: item.colorCode,
      position: 'relative',
      display: 'inline',
      padding: '5px',
      marginRight:'2px'
    } as any;
    const numberStyle = {
      position: 'relative',
      top: '-23px',
      padding:'3px 6px',
      right: '-5px',
      backgroundColor: item.colorCode,
      height: '20px',
      width: '20px',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#000000',
      fontFamily: '"Inter", Helvetica',
      fontSize: '10px',
      fontWeight: '700'
    } as any;

    // Append the text before the sentence.
    if (item.index > start) {
      highlightedParagraph.push(paragraph.substring(start, item.index));
    }

    // Append the sentence with highlight.
    const sentenceWithHighlight = (
      <span key={index} style={highlightStyle}>
        {item.sentence}
        <span style={numberStyle}>{item.articleIndex}</span>
      </span>
    );
    highlightedParagraph.push(sentenceWithHighlight);

    // Update the start index.
    start = item.index + item.sentence.length;
    
  });

  // Append the remaining text.
  if (start < paragraph.length) {
    highlightedParagraph.push(paragraph.substring(start));
  }

  return (
    <p className="text-wrapper-23 mt-10">
      {highlightedParagraph}
    </p>
  );
}

export default HighlightedParagraph;