export enum UserRole {
  User = 'User',
  Admin = 'Admin',
  Guest = 'Guest',
  Unknown = 'Unknown',
}

export enum UserModuleRole {
  User = 'user',
  Client = 'client',
  Partner = 'partner',
  Unknown = 'unknown',
}
