import { createSelector } from "@reduxjs/toolkit";
import { ProfileState } from "../slices/profile.slice";
import { AppState } from "../types/App.type";
import { getProfileErrorMessage } from "../../common/constants/profile/profile-error-message";

const selectProfileState: (state: AppState) => ProfileState = state => state.profile;

export const selectProfileErrorMessage = createSelector(selectProfileState, state =>
    getProfileErrorMessage(state.errorCode),
  );
export const isUserLoggedIn = createSelector(selectProfileState, ({ isUserLoggedIn }) => isUserLoggedIn);

export const isGuestAccount = createSelector(selectProfileState, ({ isGuestAccount }) => isGuestAccount);
export const showPasswordRecovery = createSelector(selectProfileState, ({ showPasswordRecoveryScreen  }) => showPasswordRecoveryScreen);
export const showOTPScreen = createSelector(selectProfileState, ({ showVerificationCodeScreen  }) => showVerificationCodeScreen);
export const showGuestOTPScreen = createSelector(selectProfileState, ({ showGuestOTPVerificationCode  }) => showGuestOTPVerificationCode);
export const userDetails = createSelector(selectProfileState, ({ user  }) => user);

export const isLoading = createSelector(selectProfileState, ({ loading  }) => loading);


