import { useSelector } from "react-redux"
import { ContentInput } from "../../../components/ContentInput"
import { Header } from "../../../components/Header"
import { processingInput } from "../../../redux/selectors/article.selectors"

export const ArticleProcess = () => {
    let userProcessingInput = useSelector(processingInput)
    return( <div className="landing">
    <div className="div">
        <Header
            className="header-instance"
            state="not-logged-in"
        />
        <div className="mt-20"></div>

        <div>
            <div className="md:w-7/10 w-full relative">
                <ContentInput viewType="Processing" userProcessingInput={userProcessingInput}></ContentInput>
            </div>
        </div>
        </div>
        </div>)
}