import React, { FC } from 'react';
import ExclamationCircleFilledIcon from '../../common/icons/ExclamationCircleFilledIcon';

interface PropTypes {
  errorMessage: string;
}

const Component: FC<PropTypes> = ({ errorMessage }) => {
  return (
    <div className="bg-red-50 p-3 rounded flex items-center w-full">
      <ExclamationCircleFilledIcon className="w-6 h-6 text-red-600 flex-shrink-0" />
      <div className="ml-2 text-sm">{errorMessage}</div>
    </div>
  );
};

export default Component;
