import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import "./style.css";

interface Props {
  stateProp: "disabled" | "rest" | "hover";
  className: any;
  text?:string;
  onClick?: any;
}

export const SecondaryButton = ({ stateProp, className,text,onClick  }: Props): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, {
    state: stateProp || "rest",
  });

  return (
    <button
    onClick={onClick}
      className={`secondary-button cursor-pointer state-${state.state} ${className} ${text}`}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
    >
      <div className="sign-in">{text ?? "Sign In"}</div>
    </button>
  );
};

function reducer(state: any, action: any) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        state: "hover",
      };

    case "mouse_leave":
      return {
        ...state,
        state: "rest",
      };
  }

  return state;
}

SecondaryButton.propTypes = {
  stateProp: PropTypes.oneOf(["disabled", "rest", "hover"]),
};
