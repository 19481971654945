import { Assertion, AssertionResponse, AssertionResult, Result } from "../../types/models";

export function mapApiResponseToResult(apiResponse: AssertionResult): AssertionResult {
  const colors = ['#d6f5f8','#ffe5e3','#f6eaff','#ecf0f7','#f6f9ec','#ecf1f8','#f3f6e6','#f3f6e6'];
  var result = {...apiResponse};
  result.assertions = result.assertions.map((ele, index) => ({
    ...ele,
    colorCode: colors[index % colors.length],
    articleIndex: index + 1,
  }));
 const words = result.article.split(" ");
const randomWords = [] as string[];
while (randomWords.length < 4) {
  const randomIndex = Math.floor(Math.random() * words.length);
  const randomWord = words[randomIndex];
  if (!randomWords.includes(randomWord) && randomWord.length >= 5) {
    randomWords.push(randomWord);
  }
  }
  result.tags = randomWords;
  
  return result;
}

export function mapApiDefaultToResponse(apiResponse: AssertionResponse): AssertionResult {
  return {
    id: apiResponse.id,
    userId: apiResponse.userId,
    article: apiResponse.article,
    updatedAt: apiResponse.updatedAt,
    createdBy: apiResponse.createdBy,
    createdAt: apiResponse.createdAt,
    deletedAt: apiResponse.deletedAt,
    progressPercentage: apiResponse.progressPercentage,
    assertions: apiResponse.assertions.map((result, index) => ({
      id: '', 
      articleId: '', // You need to provide a way to generate or get this articleId
      colorCode: '', // You need to provide a way to generate or get this colorCode
      articleName: apiResponse.article,
      articleIndex: index + 1, // Assuming index starts from 1
      riskAssessment: '', // You need to provide a way to generate or get this riskAssessment
      assertPosition: 0, // You need to provide a way to generate or get this assertPosition
      topRanks: [result],
    })),
  };
}


export function getSortedAssertions(assertions: Assertion[], paragraph: string): Assertion[] {
  return assertions.map((sentence) => {
    const subWords = sentence.articleName.split(' ');
    let comparetxt = '';
    if (subWords.length > 5) {
      comparetxt = subWords.slice(0, 5).join(' ');
    } else {
      comparetxt = subWords.join(' ');
    }
    const index = paragraph.indexOf(comparetxt);
    return { 
      id: sentence.id,
      articleId: sentence.articleId,
      articleName: sentence.articleName,
      assertPosition: sentence.assertPosition,
      topRanks: sentence.topRanks,
      index, 
      sentence: comparetxt, 
      colorCode: sentence.colorCode, 
      articleIndex: index 
    };
  }).filter((item) => item.index !== -1).sort((a, b) => a.articleIndex - b.articleIndex);
}


  export function extractMainDomain(url: string): string {
    const urlObject = new URL(url);
    const parts = urlObject.hostname.split('.');
    const len = parts.length;
    if (len > 2) {
      return parts[len - 2];
    }
    return parts[0];
  }

  export 
  function CurrentDateTime() {
    const date = new Date();
    const options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    const formattedDate = date.toLocaleString('en-US', options);
      return (
          <div>{formattedDate}</div>
      );
  }

  
  export function extractBaseUrl(url: string): string {
    const urlObject = new URL(url);
    return urlObject.origin;
  }

  export function getFormatedDate(date: Date): string {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

return monthNames[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();

  }

  export function wait(seconds:number) {
    return new Promise(resolve => setTimeout(resolve, seconds * 1000));
  }