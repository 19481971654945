import { useState } from "react";
import { Icons } from "../../common/icons";
import { Popper } from "react-popper";
import { useDispatch, useSelector } from "react-redux";
import { profileActions } from "../../redux/actions/profile.actions";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../common/constants/route-path";
import  maskGroup from "../../static/img/mask-group.png";
import { isMockUser } from "../../redux/selectors/activeUser.selectors";

 interface Props{
    firstName:string;
    lastName:string;
 }
 
 function generateRandomColor(firstName:string) {
  const colors = ['#343A40', '#6C757D', '#343A40', '#6C757D', '#343A40'];
  const charCodeSum = firstName
    .split('')
    .map((char) => char.charCodeAt(0))
    .reduce((sum, code) => sum + code, 0);
  const index = charCodeSum % colors.length;
  return colors[index];
}

export const LoggedInProfileAvatar = ({firstName="",lastName="" }:Props)=> {
  const initials = (firstName.charAt(0) + lastName.charAt(0)).toUpperCase();
  const randomBackgroundColor = generateRandomColor(initials);
  const enableMockUser = useSelector(isMockUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
return(
   <>
     <div className="relative inline-flex items-center cursor-pointer"  onClick={() => setIsPopoverOpen(!isPopoverOpen)}  onMouseLeave={() => setIsPopoverOpen(false)}
       >
      <div
        className="relative inline-flex items-center justify-center w-9 h-9 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600 cursor-pointer"
        style={{ backgroundColor: randomBackgroundColor }}
       
      >
        {enableMockUser ? (<img className="mask-group" alt="Mask group" src={maskGroup} />): (<span className="font-small text-white ">{initials}</span>) } 
        
      </div>
      <Icons className="icons-instance" />

      {isPopoverOpen && (
        <Popper placement="right">
          {({ ref, style, placement }) => (
            <div
              ref={ref}
              data-placement={placement}
              style={{ ...style,  zIndex: 10, left: 'unset' , right: '6px', top: '-4px' }}
              className="mt-10  text-sm relative bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600"
            >
              <ul className="py-2 text-sm text-gray-600 dark:text-gray-200">
                <li>
                  <span
                    className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer dark:hover:text-white font-sans"
                  >
                    View Profile
                  </span>
                </li>
                <li>
                  <span
                    className="block px-4 py-2 hover:bg-gray-100 cursor-pointer dark:hover:bg-gray-600 dark:hover:text-white font-sans"
                  >
                    Change Password
                  </span>
                </li>
              </ul>
              <div className="py-1">
                <p
                    onClick={() => {
                      dispatch(profileActions.logout());
                      navigate(RoutePath.Landing);
                    }} 
                  className="block px-4 py-2 text-sm text-gray-600 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                >
                  Sign out
                </p>
              </div>
            </div>
          )}
        </Popper>
      )}
    </div>
    </>
  )
}

