export const  GoogleIcon = () =>{
    return (<svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_129_1148)">
    <path d="M19.2737 9.67772C19.2737 8.8993 19.2106 8.33126 19.0739 7.74219H10.1851V11.2556H15.4026C15.2974 12.1287 14.7294 13.4436 13.4671 14.3272L13.4494 14.4448L16.2598 16.6221L16.4546 16.6415C18.2428 14.99 19.2737 12.56 19.2737 9.67772Z" fill="#4285F4"/>
    <path d="M10.1851 18.9348C12.7412 18.9348 14.8871 18.0932 16.4546 16.6416L13.4671 14.3273C12.6676 14.8848 11.5946 15.274 10.1851 15.274C7.6815 15.274 5.55662 13.6225 4.79916 11.3398L4.68813 11.3493L1.76576 13.6109L1.72754 13.7172C3.28438 16.8098 6.48225 18.9348 10.1851 18.9348Z" fill="#34A853"/>
    <path d="M4.79922 11.3398C4.59936 10.7508 4.48369 10.1196 4.48369 9.46739C4.48369 8.81516 4.59936 8.18402 4.7887 7.59495L4.78341 7.46949L1.82441 5.17151L1.7276 5.21756C1.08595 6.50093 0.717773 7.9421 0.717773 9.46739C0.717773 10.9927 1.08595 12.4338 1.7276 13.7172L4.79922 11.3398Z" fill="#FBBC05"/>
    <path d="M10.1851 3.66069C11.9628 3.66069 13.162 4.4286 13.8458 5.07032L16.5177 2.46152C14.8767 0.936221 12.7412 0 10.1851 0C6.48225 0 3.28438 2.12488 1.72754 5.21753L4.78864 7.59492C5.55662 5.31224 7.6815 3.66069 10.1851 3.66069Z" fill="#EB4335"/>
    </g>
    <defs>
    <clipPath id="clip0_129_1148">
    <rect width="18.5668" height="19" fill="white" transform="translate(0.716797)"/>
    </clipPath>
    </defs>
    </svg>);
}