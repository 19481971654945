import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import "./style.css";

interface Props {
  size: "medium" | "small";
  stateProp: "disabled" | "rest" | "hover";
  style: "boxed" | "label" | "label-bold";
  icon?: React.ReactNode;
  iconPosition?: "left" | "right";
  className: any;
  text: string;
  onClick?: any;
}

export const PrimaryButton = ({ size, stateProp, style, className, text = "Submit" , onClick , icon,iconPosition }: Props): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, {
    size: size || "medium",
    state: stateProp || "rest",
    style: style || "boxed",
  }); 

  return (
    <button
      className={`primary-button  cursor-pointer ${state.state} ${state.size} ${state.style} ${className} ${onClick}`}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
      onClick={onClick}
    >
     {icon && iconPosition === "left" && <div className="button-icon left-icon">{icon}</div>}
      <div className="try-now">{text}</div>
      {icon && iconPosition === "right" && <div className="button-icon right-icon">{icon}</div>}
    </button>
  );
};

function reducer(state: any, action: any) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        state: "hover",
      };

    case "mouse_leave":
      return {
        ...state,
        state: "rest",
      };
  }

  return state;
}

PrimaryButton.propTypes = {
  size: PropTypes.oneOf(["medium", "small"]),
  stateProp: PropTypes.oneOf(["disabled", "rest", "hover"]),
  style: PropTypes.oneOf(["boxed", "label", "label-bold"]),
  text: PropTypes.string,
};
