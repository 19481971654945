import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const MainPage = ({ children }: Props) => {
  return (
    <div className="landing">
     <div  className="div">
     {children}
     </div>
    </div>
  );
};

export default MainPage;