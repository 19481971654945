import * as React from 'react';
import classnames from 'classnames';

import { PropTypes, defaultProps } from './IconProps';

const Component: React.FC<PropTypes> = ({ className, ...rest }: PropTypes) => (
  <svg
    className={classnames('w-6 h-6 text-green-400', className)}
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);

Component.defaultProps = defaultProps;

export default Component;
