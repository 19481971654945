const IS_STORAGE_USED = 'isUsed';

class StorageService {
  storage!: Storage;

  constructor(private namespace: string = '') {
    this.initializeStorage();
  }

  private get namespaceKey() {
    return `[${this.namespace}]`;
  }

  private buildKey(key: string) {
    return this.namespace ? `[${this.namespace}] ${key}` : key;
  }

  setItem<T>(key: string, value: T) {
    key = this.buildKey(key);
    this.storage.setItem(key, JSON.stringify(value));
  }

  removeItem(key: string) {
    key = this.buildKey(key);
    this.storage.removeItem(key);
  }

  getItem<T>(key: string): T {
    key = this.buildKey(key);
    const value = this.storage.getItem(key);

    return !!value ? JSON.parse(value) : undefined;
  }

  clear() {
    this.namespace ? this.clearNamespace() : this.storage.clear();
  }

  setStorage(storage: Storage) {
    this.storage.clear();

    this.storage = storage;

    this.storage.setItem(IS_STORAGE_USED, 'true');
  }

  private clearNamespace() {
    const allKeys = Object.keys(this.storage).filter(k => k.startsWith(this.namespaceKey));

    for (const key of allKeys) {
      this.storage.removeItem(key);
    }
  }

  private initializeStorage() {
    if (localStorage.getItem(IS_STORAGE_USED)) {
      this.storage = localStorage;
    } else {
      this.storage = sessionStorage;
    }
  }
}

export { StorageService };
