import { FC } from 'react';
import Modal from '../Modal';
import { Validators } from '../../../common/helpers/form-controls/validators';
import { useForm } from '../../../common/helpers/form-controls/use-form';
import { useDispatch, useSelector } from 'react-redux';
import { layoutActions } from '../../../redux/actions/layout.actions';
import { isUserLoggedIn, showGuestOTPScreen, showOTPScreen } from '../../../redux/selectors/profile.selectors';
import { GuestSignInEmailSetup } from './GuestSignInEmailSetup';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../../common/constants/route-path';
import { OTPVerificationStep } from './OTPVerificationStep';
import { profileActions } from '../../../redux/actions/profile.actions';
interface PropTypes {
  orgId?: string;
  isShown: boolean;
  onClose?: () => void;
}

export const GuestSignIn: FC<PropTypes> = ({ isShown, onClose }) => {
  const  enableOTPScreen = useSelector(showGuestOTPScreen);
  const  isLoggedIn = useSelector(isUserLoggedIn);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  onClose = () => {
    if(isLoggedIn){
      navigate(RoutePath.Process);
    } 
  }
  return (
    <div>
      <Modal
        isShown={isShown}
        animation="fade"
        centered
        onAfterClose={onClose}
        withBackdrop
        onBackdropClick={onClose}
      >
        <div className="border rounded-md border-gray-100 border-solid bg-white shadow-md p-5 w-500 " id="SignUpModal">
          { enableOTPScreen &&  (<div className=''>
          <OTPVerificationStep isGuestAccount={true}  onBackNavigation={()=>{
              dispatch(profileActions.showGuestOTPVerificationCode(false));
          }}  />
          </div>)}
          {  !enableOTPScreen &&  (<div className=''>
          <GuestSignInEmailSetup   /></div>)  }  
        </div>
      </Modal>
    </div>
  );
};

