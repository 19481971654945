export function minLengthValidator(minLength: number) {
  return (value: string | Array<any>): boolean => {
    if (!value) return false;

    return value.length >= minLength;
  };
}

export function maxLengthValidator(maxLength: number) {
  return (value: string | Array<any>): boolean => {
    if (!value) return false;

    return value.length <= maxLength;
  };
}
