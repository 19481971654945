import { AnyAction, Store } from 'redux';
import { AppState } from '../../../redux/types/App.type';

class StoreProviderServiceImpl<IAppState> {
  private store!: Store<IAppState>;

  private get _store(): Store<IAppState> {
    if (!this.store) {
      throw new Error('Store is not initialized yet.');
    }

    return this.store;
  }

  public dispatch(action: AnyAction) {
    this.store.dispatch(action);
  }

  public setStore(store: Store<IAppState>) {
    this.store = store;
  }

  public getStore(): Store<IAppState> {
    return this._store;
  }

  public getState(): IAppState;
  public getState<T2>(selector?: (state: IAppState) => T2): T2;
  getState<T2>(selector?: (state: IAppState) => T2) {
    const state = this._store.getState();

    if (selector) {
      return selector(state);
    }

    return state;
  }
}

const StoreProviderService = new StoreProviderServiceImpl<AppState>();

export default StoreProviderService;
