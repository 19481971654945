import { FC, useRef, useState } from 'react';
import Modal from '../Modal';
import { Validators } from '../../../common/helpers/form-controls/validators';
import { useForm } from '../../../common/helpers/form-controls/use-form';
import { useDispatch, useSelector } from 'react-redux';
import { layoutActions } from '../../../redux/actions/layout.actions';
import { showGuestOTPScreen, showOTPScreen } from '../../../redux/selectors/profile.selectors';
import { CREATE_ACCOUNT, ALREADY_HAVE_ACCOUNT, SIGN_IN, GET_STARTED_NOW, DONT_HAVE_ACCOUNT, DONT_KNOW_EMAIL, REQUEST_SUPPORT, PASSWORD_RECOVERY, PASSWORD_RECOVERY_MESSAGE, SUBMIT, BACK_TO_EMAIL, BACK_TO_SIGNIN } from '../../../common/constants/app-text';
import CloseButton from '../../CloseButton/CloseButton';
import { Divider } from '../../Divider/Divider';
import Input from '../../Input';
import { LogoDetailed } from '../../LogoDetailed/LogoDetailed';
import { MainButton } from '../../MainButton';
import { MessageWithAction } from '../../MessageWithAction/MessageWithAction';
import { SignInProvidersGroup } from '../../SignInProvidersGroup/SignInProvidersGroup';
import { TermsAndConditions } from '../../TermsAndConditions/TermsAndConditions';
import { CenterLabel } from '../../CenterLabel/CenterLabel';
import { profileActions } from '../../../redux/actions/profile.actions';
import { isEmpty } from '../../../common/helpers/objects/is-empty';
import { passwordRecoveryActions } from '../../../redux/actions/password-recovery.actions';
import { passwordRecoverSelectors } from '../../../redux/selectors/password-recover.selectors';
interface PropTypes {
  orgId?: string;
  onClose?: () => void;
}

interface PasswordRecoveryModal {
  email: string;
}
const PasswordRecoveryEmailStep: FC<PropTypes> = ({  onClose }) => {
  const dispatch = useDispatch();
  const [animationClass, setAnimationClass] = useState("");
  const isProcessing =  useSelector(passwordRecoverSelectors.isLoading);
  const { handleSubmit, handleChange, data, errors } = useForm<PasswordRecoveryModal>({
    validations: {
      email: [
        {
          validator: Validators.email,
          message: "Invalid email format",
        },
      ],
    },

    onSubmit: () => {
        dispatch(passwordRecoveryActions.setPasswordRecoveryRequest(data.email));
    },
  });

  const showSignUpModal = () => {
    dispatch(layoutActions.setShowSignUpModal(true));
  }

  const closePasswordRecovery = () => {

    setAnimationClass("animate__animated animate__flipOutX");
    setTimeout(() => {
        dispatch(profileActions.showPasswordRecoveryScreen(false));
      }, 500); 
  };
  
  return (
     <div  className={`flex flex-col items-center justify-center gap-3 mt-5 ${animationClass}`}>
      <div className="block group mx-5 mt-4">
        <LogoDetailed />
      </div>
      <div className="flex flex-col items-center justify-center gap-3 animate__animated animate__flipInX">
      <h1 className="mt-4  w-full font-sans text-left text-midnight-blue font-bold">{PASSWORD_RECOVERY}</h1>
      <p className='w-full  text-gray-400 font-sansInter text-lg'>{PASSWORD_RECOVERY_MESSAGE}</p>
      <div className='w-full'>
      <Input
       id="user-email" 
       className="w-full pb-2  py-2 mt-4 " 
       type="text" size="sm" 
       placeholder='Email address'
        value={(data.email)} 
        error={!!errors.email} onChange={handleChange('email')} />
          {
          errors.email
          && <div className="text-sm font-medium text-red-600 h-5 pt-2 pb-7.5 box-content">{errors.email}</div>
          }

      </div>
      <MainButton
      isLoading={isProcessing}
      onClick={handleSubmit} 
      disabled={!isEmpty(errors) || !data.email} className="w-full w-100 px-4  mt-2" stateProp="rest" text={SUBMIT} />
      <MessageWithAction
      
      
      message={DONT_KNOW_EMAIL} onAction={showSignUpModal} action={REQUEST_SUPPORT} />
      <Divider />
      <CenterLabel onClick={closePasswordRecovery}   text={BACK_TO_SIGNIN} ></CenterLabel>
      </div>
    </div>
  );
};

export default PasswordRecoveryEmailStep;

