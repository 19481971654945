import { ChangeEvent, FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { ValidationArguments } from './validators';
import { isEmpty } from '../objects/is-empty';

type ValidatorFn<T> = (value: T, validationArguments: ValidationArguments) => boolean;

interface Validation<T> {
  validator: ValidatorFn<T>;
  message: string;
}

type Validations<T extends Record<keyof T, any>> = Partial<Record<keyof T, Validation<any>[]>>;

interface UseFormOptions<T> {
  validations?: Validations<T>;
  initialValues?: Partial<T>;
  onSubmit?: () => void;
}

type ErrorRecord<T> = Partial<Record<keyof T, string>>;

export const useForm = <T extends Record<keyof T, any> = {}>(options: UseFormOptions<T>) => {
  const initial = useRef(options?.initialValues);
  const [data, setData] = useState<T>((options?.initialValues || {}) as T);
  const [errors, setErrors] = useState<ErrorRecord<T>>({});
  const [isOnSubmitInvoked, setIsOnSubmitInvoked] = useState(false);

  const handleChange = <S extends unknown>(key: keyof T, sanitizeFn?: (value: string) => S) => {
    return (event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
      const value = sanitizeFn ? sanitizeFn(event.target.value) : event.target.value;
      setData({ ...data, [key]: value });
    };
  };

  const handleSubmit = async (event?: FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    const formErrors = validateForm();

    if (!isOnSubmitInvoked) {
      setIsOnSubmitInvoked(true);
    }

    if (options?.onSubmit && isEmpty(formErrors)) {
      options.onSubmit();
    }
  };

  const validateForm = useCallback(() => {
    const formValidations = options?.validations;
    const newErrors: ErrorRecord<T> = {};

    if (formValidations) {
      for (const key in formValidations) {
        const value = data[key];
        const fieldValidations = formValidations[key];

        for (const validation of fieldValidations!) {
          const validator = validation?.validator;
          if (!validator(value, { object: data })) {
            newErrors[key] = validation?.message;
          }
        }
      }
    }

    return isEmpty(newErrors) ? {} : newErrors;
  }, [data, options?.validations]);

  const initialize = useCallback((initialValues: Partial<T>) => {
    if (!initialValues) {
      setData(initial.current as T);
    } else {
      initial.current = initialValues;
      setData(initialValues as T);
    }
    setErrors({});
    setIsOnSubmitInvoked(false);
  }, []);

  useEffect(() => {
    if (isOnSubmitInvoked) {
      const formErrors = validateForm();
      setErrors(formErrors);
      setIsOnSubmitInvoked(false); 
    }
  }, [data, isOnSubmitInvoked, validateForm]);

  return {
    data,
    errors,
    handleChange,
    handleSubmit,
    initialize,
  };
};
