import { createSlice } from '@reduxjs/toolkit'
import { AssertionResult } from '../../types/models'
import { DefaultAssertionsData } from '../../common/constants/defaultArticle'
import { mapApiResponseToResult } from '../../common/helpers/Helper'
import { ARTICLE_STORE_NAME, articleActions } from '../actions/article.actions'
import { PROCESS_ARTICLE_SAGA, processArticleSaga } from '../sagas/article.saga'

export interface ArticleState {
  currentArticle?: AssertionResult | any,
  defaultArticle: AssertionResult,
  userInput: string,
  isProcessingArticle: boolean,
  enableArticleResults: boolean,
  processArticleEnabled: boolean,
  errorCode:string;
  articlesList: AssertionResult[]
}




const initialState: ArticleState = {
    userInput: '',
    currentArticle: null,
    isProcessingArticle:false,
    enableArticleResults: false,
    processArticleEnabled:false,
    errorCode:'',
    defaultArticle: mapApiResponseToResult(DefaultAssertionsData),
    articlesList: []
}
export const articleSlice = createSlice({
  name: ARTICLE_STORE_NAME,
  initialState,
  reducers: {
    processArticle: (state, action) => {
      state.isProcessingArticle = true;
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(articleActions.processArticle, (state,{payload}) => {
      state.isProcessingArticle = true;
      state.userInput = payload;
    })
    .addCase(articleActions.getDefaultArticleSuccess, (state) => {
      state.defaultArticle = mapApiResponseToResult(DefaultAssertionsData);
    })
    .addCase(articleActions.processArticleSuccess, (state, { payload }) => {
      state.currentArticle = mapApiResponseToResult(payload) ;
      state.isProcessingArticle = false;
      state.errorCode = '';
    })
    .addCase(articleActions.processArticleFailure, (state, { payload }) => {
      state.isProcessingArticle = false;
      state.errorCode = payload.message;
    })
    .addCase(articleActions.getUserArticlesListSuccess, (state, { payload }) => {
      state.articlesList = payload;
    })
    .addCase(articleActions.processArticleEnabled, (state, { payload }) => {
      state.processArticleEnabled = payload;
    })
  }
});

export const { processArticle } = articleSlice.actions;
export const articleReducer = articleSlice.reducer;
