import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, useNavigate } from "react-router-dom";
import { articleActions } from "../../redux/actions/article.actions";
import { articleErrorCodes, isProcessingArticle, processArticleEnabled } from "../../redux/selectors/article.selectors";
import { PLACEHOLDER_FOR_USERINPUT, PROCESSING_WAITING_MESSAGE_GUEST, PROCESSING_WAITING_MESSAGE_USER } from "../../common/constants/app-text";
import { MainButton } from "../MainButton";
import ProgressBar from "../Progressbar/Progressbar";
import { RoutePath } from "../../common/constants/route-path";
import { isGuestUser, isLoggedInUser, isMockUser } from "../../redux/selectors/activeUser.selectors";
import { SignInGroupPrimary } from "../SingInGroupPrimary/SignInGroupPrimary";
import { layoutActions } from "../../redux/actions/layout.actions";
import { setNotification } from "../../redux/actions/notification.actions";

interface ContentInputProps {
  userProcessingInput?: string;
  viewType?: "Processing" | "Results" | "Input";
}



export function ContentInput({ userProcessingInput, viewType }: ContentInputProps) {
  const [content, setContent] = useState<string>(userProcessingInput ?? '');
  const isLoggedIn = useSelector(isLoggedInUser);
  const loggedInAsGuest = useSelector(isGuestUser);
  const mockAsLoggedInUser = useSelector(isMockUser);
  const isArticleProcessing = useSelector(processArticleEnabled);
  const errorCode = useSelector(articleErrorCodes);
  const [charCount, setCharCount] = useState<number>(0);
  const dispatch = useDispatch();
  const showProgressBar = useSelector(isProcessingArticle);
  const navigate = useNavigate();
  useEffect(() => {
    if ((isLoggedIn || loggedInAsGuest)&& isArticleProcessing) {
      dispatch(articleActions.processArticle(content));
      navigate(RoutePath.Process);
    }
  }, [isLoggedIn,loggedInAsGuest,showProgressBar]);


  const handleClick = () => {
    try {
      dispatch(articleActions.processArticleEnabled(true));
      if(isLoggedIn || loggedInAsGuest){
        dispatch(articleActions.processArticle(content));
        navigate(RoutePath.Process);
        return;
      }else{
        dispatch(layoutActions.setShowGuestSignUpModal(true));
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleProgressNavigation = () => {
    if(errorCode && errorCode.length){
      dispatch(setNotification({ title: 'Unable to process article.. ', type: 'error' }));
      navigate(RoutePath.Landing);
    }else{
      navigate(RoutePath.Results);
    }
  }

  return (
    <div className="">
      {viewType === 'Processing' && <div className="flex text-center mt-5">
        <div className="w-4/5 md:w-4/5 mx-auto">
          <div className="flex space-x-4">
            <div className="text-wrapper-25">Input</div>
          </div>
          <div className="flex space-x-4">
          </div>
        </div>
      </div>}

      <div className="flex justify-center items-center mt-2 mb-2 animate__animated animate__fadeInDown">
        <textarea
          style={{ backgroundColor: charCount > 0 ? 'white' : '#f0f1f9' }}
          className={`content-input relative w-4/5 md:w-4/5  p-4 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent text-lg text-base ${viewType === 'Processing' ? 'text-gray-500' : ''}`}
          value={content}
          disabled={viewType === 'Processing'}
          placeholder={PLACEHOLDER_FOR_USERINPUT}
          onChange={(e) => {
            setContent(e.target.value);
            setCharCount(e.target.value.length);
          }}
        />
      </div>
      <div className="flex text-center mt-10 mb-5 animate__animated animate__zoomIn">
        <div className="w-4/5 md:w-4/5 mx-auto">
          {(viewType === 'Processing') && <ProgressBar navigate={handleProgressNavigation} stop={!showProgressBar} />}
        </div>
      </div>
      {viewType === 'Processing' && <>
        <div className="flex justify-center items-center mt-2 mb-2  animate__animated animate__fadeInUp">
          <div className={`flex flex-col md:flex-row justify-between py-4 ${(isLoggedIn && !loggedInAsGuest) ? '' : ' w-4/5 md:w-4/5 '}`}>
            <div className="flex space-x-6 items-center  mb-4 md:mb-0 md:mr-4">
              <p className="don-t-want-to-wait">
                <span className=" text-blue-600 font-bold">Don’t want to wait? </span>
                <span className="text-gray-500 "> {(isLoggedIn && !loggedInAsGuest) ? PROCESSING_WAITING_MESSAGE_USER : PROCESSING_WAITING_MESSAGE_GUEST} </span>
              </p>
            </div>
            {!(isLoggedIn && !loggedInAsGuest) && (<SignInGroupPrimary/>)}
          </div>
        </div>
      </>}
      {!viewType && <div className="flex justify-center items-center mt-2 mb-2 gap-10 animate__animated animate__fadeInUp">
        <div className="w-4/5 md:w-4/5 flex space-x-4 items-center justify-end">
          <div className="text-wrapper-34">{charCount} characters</div>
          <MainButton  disabled={content.length < 3} onClick={handleClick} className="" stateProp="rest" text="Submit" />
        </div>
        <div className="group-10">
          <div className="relative h-auto min-h-[185px] w-full">
            <div className="absolute top-0 left-0 w-full h-full min-h-[185px] bg-center bg-no-repeat bg-cover" style={{
              backgroundImage:"url('https://generation-sessions.s3.amazonaws.com/992bca50d1a1be566686effdf99311ae/img/group-47.png')"
            }}>
            </div>
            <div className="relative w-full min-h-[185px] mt-4">
              <div className="absolute top-0 left-0 w-full h-full min-h-[142px] bg-center bg-no-repeat bg-cover" style={{
                backgroundImage:"url('https://generation-sessions.s3.amazonaws.com/992bca50d1a1be566686effdf99311ae/img/union.svg')"
              }}>
              </div>
            </div>
          </div>
        </div>
      </div>}
    </div>
  );
}

export default ContentInput;