import { createSelector } from "@reduxjs/toolkit";
import { LayoutState } from "../slices/layout.slice";
import { AppState } from "../types/App.type";

const selectLayoutState: (state: AppState) => LayoutState = state => state.layout;


export const selectShowSignInModal = createSelector(selectLayoutState, ({ showSignInModal }) => showSignInModal);

export const selectShowGuestModal = createSelector(selectLayoutState, ({ showGuestSignUpModal }) => showGuestSignUpModal);

export const selectShowSignUpModal = createSelector(selectLayoutState, ({ showSignUpModal }) => showSignUpModal);

