import { NotificationProps } from '../../common/models/notification/notification';
import { CLEAR_NOTIFICATION, NotificationActionType, SET_NOTIFICATION } from '../types/Notification.type';

export const initialNotificationState: NotificationProps = {};

export function notificationReducer(
  state = initialNotificationState,
  action: NotificationActionType,
): NotificationProps {
  switch (action.type) {
    case SET_NOTIFICATION:
      return { ...action.payload };
    case CLEAR_NOTIFICATION:
      return {};
    default:
      return state;
  }
}
