import { useState } from "react";
import { AssertionResultsList } from "../AssertionResultsList/AssertionResultsList";
import { AssertionsContainerProps } from "../../common/models/Props/props";
import React from "react";
import { useSelector } from "react-redux";
import {  isGuestUser, isLoggedInUser, maxResultsToShow } from "../../redux/selectors/activeUser.selectors";
import { isGuestAccount } from "../../redux/selectors/profile.selectors";

  export const AssertionsContainer: React.FC<AssertionsContainerProps> = ({ assertion , fullWidth , enableOverlay }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const isLoggedIn = useSelector(isLoggedInUser);
    const maxResults= useSelector(maxResultsToShow);
    const isGuestAccount = useSelector(isGuestUser);
    const renderedResults = ((isLoggedIn && !isGuestAccount)  && !fullWidth) ? assertion.assertions : assertion.assertions.slice(0, maxResults);
    
    return (
      <>
      <div className={`group-2 ${fullWidth ? 'w-full' : 'w-95'}`}>
        <div className="overlap-group-2">
          <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
            {
              renderedResults.map((result, index) => (
                <li key={index} className={`mr-1 border-2 border-gray-200 dark:border-gray-700 rounded-t-lg }`}>
                  <button 
                    aria-current="page"
                    className={`inline-block p-4 text-blue-600 bg-gray-100 border-b border-gray-100 dark:bg-gray-800 dark:text-blue-500 custom-nav-tab ${currentIndex === index ? 'nav-border-active' : 'nav-border'}` }
                    onClick={() => setCurrentIndex(index)}
                  >
                    <div className="ellipse-6" style={{backgroundColor: result.colorCode}} />
                    <div className="text-wrapper-5">{index+1}</div>
                  </button>
                </li>
              ))
            }
          </ul>
          <div className="rectangle-6" />
        </div>
        <div className="navtab-result-container">
          <AssertionResultsList stateProp="" assertion={assertion?.assertions[currentIndex]} assertionCount={currentIndex+1} />
        </div>
      </div>
       {enableOverlay && (<div className="rectangle-17" />)} </>
    );
  }