import React, { FC, ReactNode, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import ModalWrapper from './ModalWrapper';

interface PropTypes {
  children: ReactNode;
  isShown: boolean;
  className?: string;
  withBackdrop?: boolean;
  animation?: 'none' | 'fade';
  centered?: boolean;
  onBackdropClick?: () => void;
  onAfterClose?: () => void;
}

const defaultProps: Partial<PropTypes> = {
  animation: 'none',
  onAfterClose: () => {},
};

const Component: FC<PropTypes> = React.memo(
  ({ isShown, children, className, animation, withBackdrop, centered, onBackdropClick, onAfterClose }) => {
    const [modalContainer, setModalContainer] = useState<HTMLElement>();
    const [renderModal, setRenderModal] = useState(false);

    useEffect(() => {
      let element = document.getElementById('modal');

      if (element) {
        setModalContainer(element);
      } else {
        element = document.createElement('div');
        element.setAttribute('id', 'modal');
        setModalContainer(element);
        document.body.appendChild(element);
      }
    }, []);

    useEffect(() => {
      if (isShown) setRenderModal(true);

      if (!isShown && animation === 'none') {
        setRenderModal(false);

        onAfterClose!();
      }
    }, [isShown]);

    const onAnimationEnd = () => {
      if (!isShown) {
        setRenderModal(false);
      }

      onAfterClose!();
    };

    if (!modalContainer || !renderModal) {
      return null;
    }

    return ReactDOM.createPortal(
      <ModalWrapper
        isShown={isShown}
        className={className}
        onBackdropClick={onBackdropClick}
        onAnimationEnd={onAnimationEnd}
        animation={animation!}
        withBackdrop={withBackdrop}
        centered={centered}
      >
        {children}
      </ModalWrapper>,
      modalContainer,
    );
  },
);

Component.defaultProps = defaultProps;

export default Component;
