import { createSlice } from "@reduxjs/toolkit";
import { LAYOUT_STORE_NAME, layoutActions } from "../actions/layout.actions";


export interface LayoutState {
    showSignInModal: boolean;
    showSignUpModal: boolean;
    showGuestSignUpModal: boolean;
}


export const initialLayoutState: LayoutState = {
    showSignInModal: false,
    showSignUpModal:false,
    showGuestSignUpModal:false
}
const layoutSlice = createSlice({
    name: LAYOUT_STORE_NAME,
    initialState: initialLayoutState,
    reducers: {},
    extraReducers: builder => {
        builder
          .addCase(layoutActions.setShowSignInModal, (state, { payload }) => {
            state.showSignInModal = payload;
            state.showSignUpModal = false;
          })
          .addCase(layoutActions.setShowSignUpModal, (state, { payload }) => {
            state.showSignInModal = false;
            state.showSignUpModal = payload;
          })
          .addCase(layoutActions.setShowGuestSignUpModal, (state, { payload }) => {
            state.showGuestSignUpModal = payload;
          })
        }
});


export const layoutReducer = layoutSlice.reducer;
