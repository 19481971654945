import { useState } from "react";
import { CurrentDateTime } from "../../common/helpers/Helper";
import { AssertionResult } from "../../types/models";
import HighlightedParagraph from "../HighlightedParagraph/HighlightedParagraph";
import "./style.css";
import { PrimaryButton } from "../PrimaryButton";
import { ArrowDown } from "../../common/icons/Icons";
interface ResultViewProps {
    text?: string;
    group?: string;
    assertion?:AssertionResult,
    showTags?:boolean
    disableHideInput?:boolean
  }




export const ArticleResultView = ({text,group,assertion,showTags,disableHideInput = false}:ResultViewProps): JSX.Element => {
    const [isOpen, setIsOpen] = useState(true);
    return (
      <div className="group-6">
        <div className="overlap-3">
        <div className="flex items-center justify-between">
            <div className="flex space-x-4">
              <div className="text-wrapper-25">Input</div>
            </div>
            <div className="flex space-x-4">
              <p className="text-wrapper-31 ">{CurrentDateTime()}</p>
            </div>
  
          </div>
          <div className={ ` collapsible-section ${isOpen ? 'open' : ''}`}>
          <HighlightedParagraph currentAssertion = {assertion} />
          {showTags && (<div className="flex items-center justify-start mt-10 ">
            {assertion?.tags?.map((tag) => (
              <div className="tags-wrapper mx-2">
          <span key={tag} className="tag capitalize">
            {tag}
          </span>
          </div>
        ))}
          </div>)}
          </div>
         
          <div className="flex items-center justify-between mt-10 ">
            <div className="flex space-x-4">
              <div className="input-action-item">
                <div className="text-wrapper-26"></div>
                <div className="text-wrapper-27">Favorite</div>
              </div>
  
              <div className="input-action-item">
                <div className="text-wrapper-28"></div>
                <div className="text-wrapper-29">Share</div>
              </div>
              <div className="input-action-item">
                <div className="text-wrapper-30"></div>
                <div className="text-wrapper-29">Edit Text</div>
              </div>
            </div>
            <div className="flex space-x-4">
              {isOpen ?(
              <div className="input-action-item"  onClick={() => {
                if(!disableHideInput){
                  setIsOpen(false);
                }
              }}  >
              <div className="text-wrapper-27">Hide Input</div>
              <div className="text-wrapper-26"></div>
            </div>):(<PrimaryButton
              onClick={() => setIsOpen(true)} 
            className=""
            size="small"
            stateProp="rest"
            style="boxed"
            icon={<ArrowDown />}
            iconPosition="right"
            text="Show Input"
          />) }

            </div>
          </div>
          <div className="rectangle-16 mb-10" />
        </div>
      </div>
    );
  }
  