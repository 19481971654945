import { NotificationProps } from '../../common/models/notification/notification';

export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';

interface SetNotificationAction {
  type: typeof SET_NOTIFICATION;
  payload: NotificationProps;
}

interface ClearNotificationAction {
  type: typeof CLEAR_NOTIFICATION;
}

export type NotificationActionType = SetNotificationAction | ClearNotificationAction;
