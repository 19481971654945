import { useDispatch, useSelector } from "react-redux";
import { FeaturesList } from "../../../components/FeaturesList/FeaturesList";
import PasswordCreationForm from "../../../components/PasswordCreationForm";
import { passwordRecoverSelectors } from "../../../redux/selectors/password-recover.selectors";
import { passwordRecoveryActions } from "../../../redux/actions/password-recovery.actions";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../../common/constants/route-path";
import { PasswordRecoveryStage } from "../../../redux/slices/password-recovery.slice";

export const NewPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector(passwordRecoverSelectors.selectToken);
  const passwordState = useSelector(passwordRecoverSelectors.selectStage);
  const isResetting = false;
  const handleSubmit = (password: string) => {
    dispatch(passwordRecoveryActions.resetPassword({ password, token }));
    navigate(RoutePath.Landing);
  };

  return (
    <div className="flex flex-wrap justify-center">
      <div className="w-full md:w-2/5 px-4 border-r card">
        <PasswordCreationForm onSubmit={handleSubmit} isResetting={isResetting} />
      </div>
      <div className="w-full md:w-3/5 px-4">
        <div className="hidden md:block">
          <FeaturesList />
        </div>
      </div>
    </div>
  );    
};