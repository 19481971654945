import { HttpClient } from '../http';
import { PostUserRequest } from './models/post-user-request';
import { UserResponse } from './models/user-response';
import { PutUserRequest } from './models/put-user-request';
import { UserQueryParams } from './models/user-query-params';
import { User } from '../../models/user/user';

class UserServiceImpl {
  get(orgId: string, query: UserQueryParams = { pageSize: 25 }) {
    return HttpClient.get<UserResponse>('/users', { params: { orgId, ...query } }).then(response => response.data);
  }

  getById(id: string) {
    return HttpClient.get<UserResponse>(`/user/${id}`).then(response => response.data);
  }

  post(request: PostUserRequest) {
    return HttpClient.post<User>('/user', request, {portNumber:3002}).then(
      response => response.data,
    );
  }
  put(id: string, request: PutUserRequest) {
    return HttpClient.put<UserResponse>(`/user/${id}`, request).then(response => response.data);
  }

  delete(userId: string, organizationId: string) {
    return HttpClient.delete<null>(`/user/${userId}/organization/${organizationId}`).then(response => response.data);
  }
}

const UserService = new UserServiceImpl();

export { UserService };
