import ContentLayout from "../../../components/ContentLayout/ContentLayout";
import { Footer } from "../../../components/Footer";
import { Header } from "../../../components/Header";
import MainPage from "../../../components/MainPage/MainPage";
import  {NewPassword}  from "./Newpassword";

export const PasswordRecovery = (): JSX.Element => {
    return (
        <MainPage>
            <Header />
            <ContentLayout>
             <NewPassword/>
            </ContentLayout>
            <Footer/>
        </MainPage>
    );
};