import { AppleIcon } from "../../common/icons/AppleIcon";
import { GoogleIcon } from "../../common/icons/GoogleIcon";
import { MicroSoftIcon } from "../../common/icons/MicroSoftIcon";
import RoundedBorderBox from "../RoundedBorderBox/RoundedBorderBox";

export const SignInProvidersGroup = () => {
    return (<div className="flex flex-col items-center justify-center w-full">
        <span className="mb-2 text-midnight-blue font-bold">or sign in with:</span>
        <div className="flex items-center justify-between w-full px-4 gap-10 ">
            <RoundedBorderBox>
                <GoogleIcon />
            </RoundedBorderBox>
            <RoundedBorderBox>
                <MicroSoftIcon />
            </RoundedBorderBox>
            <RoundedBorderBox>
                <AppleIcon />
            </RoundedBorderBox>
        </div>
    </div>);
}