import { FC } from 'react';
import Modal from '../Modal';
import { Validators } from '../../../common/helpers/form-controls/validators';
import { useForm } from '../../../common/helpers/form-controls/use-form';
import { useDispatch, useSelector } from 'react-redux';
import { layoutActions } from '../../../redux/actions/layout.actions';
import { SignUpOTPStep } from './SignUpOTPStep';
import { SignUpEmailStep } from './SignUpEmailStep';
import { showOTPScreen } from '../../../redux/selectors/profile.selectors';
import { passwordRecoverSelectors } from '../../../redux/selectors/password-recover.selectors';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../../common/constants/route-path';
import { setNotification } from '../../../redux/actions/notification.actions';

interface PropTypes {
  orgId?: string;
  isShown: boolean;
  onClose?: () => void;
}

const SignUpPage: FC<PropTypes> = ({ isShown, onClose }) => {
  var navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector(passwordRecoverSelectors.selectToken);
  const  enableOTPScreen = useSelector(showOTPScreen);
  const onSignUpModalClose = () => {
    if(token){
      dispatch(setNotification({ title: 'Setup password for your account', type: 'success' }));
      navigate(RoutePath.PasswordRecovery);
    }
  }

  const onSignUpClose = () => {
    dispatch(layoutActions.setShowSignUpModal(false));
  }
  return (
    <div>
      <Modal
        isShown={isShown}
        animation="fade"
        centered
        withBackdrop
        onAfterClose={onSignUpModalClose}
        onBackdropClick={onClose}
      >
        <div className="border rounded-md border-gray-100 border-solid bg-white shadow-md p-5 w-500 " id="SignUpModal">
          { enableOTPScreen && (<div className=''>
          <SignUpOTPStep onBackNavigation={onSignUpClose}  />
          </div>)}
          {  !enableOTPScreen &&  (<div className=''>
          <SignUpEmailStep   onSignInClose={onSignUpClose} /></div>)  }  
        </div>
      </Modal>
    </div>
  );
};

export default SignUpPage;

