import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const RoundedBorderBox = ({ children }: Props) => {
  return (
    <div className="border rounded-md cursor-pointer border-gray-100 border-solid bg-white px-12 py-4">
      {children}
    </div>
  );
};

export default RoundedBorderBox;