export class ArticleProcessRequest{
    article: string;
    top_k: number;
    top_n: number;
    recursion_level: number;
    constructor(article:string){
        this.article = article ?? '';
        this.top_k = 2;
        this.top_n = 2;
        this.recursion_level = 2;   
    }
    toPlainObject() {
        return {
          article: this.article,
          top_k: this.top_k,
          top_n: this.top_n,
          recursion_level: this.recursion_level,
        };
      }
  }

