export enum KeyboardKey {
    Backspace = 'Backspace',
    Enter = 'Enter',
    Escape = 'Escape',
    Space = ' ',
    ArrowLeft = 'ArrowLeft',
    ArrowUp = 'ArrowUp',
    ArrowRight = 'ArrowRight',
    ArrowDown = 'ArrowDown',
    Delete = 'Delete',
  }
  