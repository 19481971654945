import { useDispatch, useSelector } from "react-redux";
import { profileActions } from "../../redux/actions/profile.actions";
import { PrimaryButton } from "../PrimaryButton"
import { LoginForm } from "../../types/models";
import { layoutActions } from "../../redux/actions/layout.actions";
import { isMockUser } from "../../redux/selectors/activeUser.selectors";

export const SingInGroup = (): JSX.Element => {
  const dispatch = useDispatch();
  const mockAsLoggedInUser = useSelector(isMockUser);
  const onSignInClick = () => {
    if(mockAsLoggedInUser){
      dispatch(profileActions.setMockUser());
    }else{
      dispatch(layoutActions.setShowSignInModal(true));
    }
  }
  const onSignUpClick = () => {
    if(mockAsLoggedInUser){
      dispatch(profileActions.setMockUser());
    }else{
      dispatch(layoutActions.setShowSignUpModal(true));
    }
  }
    return(<>
    <PrimaryButton
            className="mx-2"
            size="small"
            stateProp="rest"
            style="boxed"
            text="Create Account"
            onClick={onSignUpClick}
          />
    <PrimaryButton  onClick={onSignInClick}  className="" size="small" stateProp="rest" style="label-bold" text="Sign In" /></>)
} 