import { AppState } from "../../../redux/types/App.type";
import { AssertionResponse, AssertionResult, HttpErrorResponse } from "../../../types/models";
import { HttpClient } from "../http";
import { ArticleProcessRequest } from "./models/ArticleProcessRequest";

class ArticleServiceImpl{

     processArticle(request: ArticleProcessRequest): Promise<AssertionResult> {
      const isDemoURL = process.env.REACT_APP_ENABLE_MOCK === 'true';  
      const baseURL = isDemoURL ? '/demo/articles':'/articles';
        return  HttpClient.post<AssertionResult>(baseURL, request, {
          portNumber:5001,
          headers: { 'Content-Type': 'application/json' },
          
        }).then(response=>response.data).catch((err: HttpErrorResponse) => {
          throw err;
        });
       }  

      getUserArticlesById(id: string): Promise<AssertionResult[]> {
        return HttpClient.get<AssertionResult[]>(`/userarticles/${id}`,{portNumber:5001})
        .then(response=>response.data);  
      }


      getDefaulArticle(): Promise<AssertionResult> { 
        return HttpClient.get<AssertionResult>('/defaultarticle',{portNumber:5001}).then(response=>response.data)
       }
}

const ArticleService = new ArticleServiceImpl();

export { ArticleService };
